import React from "react";
import InputLabeled from "../../common/form-input-labeled";

const TimeRow = props => {

    const {callBackTimeRow, time, limit, index} = props;


    const onChangeHandler = (label, val) => {

        const timeValue = (label === "time") ? val : time
        const limitValue = (label === "limit") ? val : limit

        callBackTimeRow(index, "update", {time: timeValue, limit: limitValue})
    }

    const onDeleteHandler = () => {
        callBackTimeRow(index, "delete");
    }


    return (
        <div className="mb-10 d-flex-wrap">
            <div className="mr-10">
                <InputLabeled label='Время' type='time' initValue={time} onChange={(val) => onChangeHandler('time', val)} />
            </div>
            <div className="mr-10">
                <InputLabeled className='w-100' label='Лимит' type='number' initValue={time} onChange={(val) => onChangeHandler('limit', val)} />
            </div>
            <div className="mt-25">
                <button className="button-alt" onClick={onDeleteHandler}>X</button>
            </div>

        </div>
    )

}

export default TimeRow;
