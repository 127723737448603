import React from "react";
import OperationsStatusControl from "./operations-status-control";


const OperationsApplyRowItem = props =>  {

    const { index, callBackOrder, orderItem } = props;

    const deleteOrderHandler = () => {
        callBackOrder(null, "delete", index);
    }

    const changeStatusHandler = (status) => {
        callBackOrder(null, "status", index, status);
    }


    let orderItemValue = orderItem || {};

    // case of "Reserved"
    if (typeof orderItemValue === "string") {
        orderItemValue = {
            status: false,
            person: "имя скрыто"
        }
    } else {
        orderItemValue.status = orderItemValue.status === true ? orderItemValue.status : false;
    }

    return (
            <div className="d-flex-wrap">
                <div className="p-10 mr-20 ww">
                    <p className='fs-10 mb-5'>Имя</p>
                    <label>{orderItemValue.person}</label>
                </div>
                <div className="p-10 mr-20 ww">
                    <p className='fs-10 mb-5'>Телефон</p>
                    <label>{orderItemValue.phone || 'нет' }</label>
                </div>
                <div className="p-10 mr-20 ww">
                    <p className='fs-10 mb-5'>Инфо</p>
                    <label>{orderItemValue.info || 'нет'}</label>
                </div>
                <div className="w-50 p-10 mr-20">
                    <p className='fs-10 mb-5'>Статус</p>
                    <OperationsStatusControl callBackUpdate={changeStatusHandler} checked={orderItemValue.status} />
                </div>
                <div className="w-100 p-10 mr-20">
                    <p className='fs-10 mb-5'>Действия</p>
                    <button className="button-link" onClick={deleteOrderHandler}>Удалить</button>
                </div>

            </div>
    )

}

export default OperationsApplyRowItem
