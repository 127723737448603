import React from 'react';
import MPContainer from "../mobile-preview/mobile-preview-container/mp-container";
import {connect} from "react-redux";
import Tabbar from "../tabbar/tabbar";
import {updateSection} from "../../actions";
import Form from "../form-controls/form";
import ControlsGenerator from "../form-controls/controls-generator/controls-generator";
import SaveButton from "../savebutton/save-button";
import OperationsView from "../operations-view/operations-view";
import {Redirect} from "react-router";
import AdminHeader from "./admin-header";

const Admin = props => {

    const { data, section, texts } = props;
    const {adminPage: {previewText}} = texts.screens;

    const isOperation = section === "operations";

    if (!data.mainLabel) {
        return <Redirect to='/' />
    }
    return (
        <div className='admin-main-container'>

            <AdminHeader />

            <Tabbar  />

            <div className="admin-container">

                <div className={"admin-form " + section}>
                    <div>
                        <h4>{data.mainLabel}</h4>
                        <div className='separator1px mt-10' />
                    </div>

                    {
                        !isOperation && <Form />
                    }
                    {
                        isOperation && <OperationsView />
                    }

                    <div className="bottom-space" />

                </div>
                {
                    !isOperation &&
                    <div className="admin-preview">

                        <h4>{previewText}</h4>
                        <div className='separator1px mt-10' />
                        <div className="admin-phone-preview">
                            <MPContainer/>
                        </div>
                    </div>
                }
                {
                    !isOperation &&
                    <div className="save-container">
                        <div className='mr-10'>
                            <ControlsGenerator />
                        </div>
                        <div>
                            <SaveButton />
                        </div>
                    </div>
                }


                <div className="bottom-space" />
            </div>
        </div>
    );
}

const mapStateToProps = ({ user: { data, section }, language: { texts }}) => {
    return { data, section, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSection: updateSection(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
