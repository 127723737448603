import React, {useMemo} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";

import { Eventcalendar, localeRu } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css'

const SchedulerView = props => {

    const { servicesData } = props;

    const view = useMemo(() => {
        return {
            schedule: {
                type: 'week',
                allDay: false,
                startDay: 1,
                endDay: 7,
                startTime: '07:00',
                endTime: '21:00'
            }
        };
    }, []);

    return (
        <div className="mt-20">
            <Eventcalendar
                theme="ios"
                themeVariant="light"
                clickToCreate={false}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                locale={localeRu}
                view={view}
                data={servicesData}
            />
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerView);
