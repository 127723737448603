import React, {Component} from "react";
import {getItemById} from "../../../../utils";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";


class ServicePreviewDateTime extends Component {

    render () {

        const data = getItemById(this.props.data[this.props.section], this.props.id)
        const value = data.value
        const time = data.value.time

        return (
            <div className="service-preview-item">

                <h3>{value.date}</h3>

                <div>
                    {time &&

                    time.map(
                        (item, index) => {

                            return (
                                <button key={index} className="button-alt">{item.time}</button>
                            )
                        }
                    )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreviewDateTime);