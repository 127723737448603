import React, {Component} from "react";
import {getItemById} from "../../../utils";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import ServicePreviewDateTime from "./service-apply/service-preview-datetime";
import ServicePreviewDate from "./service-apply/service-preview-date";
import ServicePreviewApply from "./service-apply/service-preview-apply";
import ServicePreviewPayment from "./service-payment/service-preview-payment";
import ServicePreviewDelivery from "./service-delivery/service-preview-delivery";


class ServicePreviewContainer extends Component {


    renderServiceItem = (item) => {

        switch(item.childType) {

            case 'date_time':  return <ServicePreviewDateTime  id={item.id} key={item.id} />;
            case 'date': return <ServicePreviewDate id={item.id} key={item.id}/>;
            case 'apply': return <ServicePreviewApply id={item.id} key={item.id}/>;
            case 'paycard': return  <ServicePreviewPayment id={item.id} key={item.id}/>;
            case 'payerip': return  <ServicePreviewPayment id={item.id} key={item.id}/>;
            case 'delivery': return <ServicePreviewDelivery id={item.id} key={item.id}/>;

            default: return null;
        }
    }

    renderButtonLabel = (type) => {

        switch(type) {

            case 'service_apply':  return "Записаться";
            case 'service_delivery': return "Оформить онлайн доставку";
            case 'service_payment': return "Онлайн-оплата";

            default: return "";
        }
    }

    render () {

        const data = getItemById(this.props.data[this.props.section], this.props.id);

        if (data.value === "off") return null;

        const buttonLabel = this.renderButtonLabel(data.serviceType);
        const children = data.children

        return (
            <div className="service-preview-item">

                <h2>{data.label}</h2>

                <div>
                    {children &&

                    children.map(
                        (item) => {

                            return (
                                this.renderServiceItem(item)
                            )
                        }
                    )
                    }
                </div>
                <p>
                    <button className="button">{buttonLabel}</button>
                </p>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreviewContainer);