import React, { useState } from "react";
import FormItemMenu from "./form-item-menu";
import {ServiceItem, NewsItem, ImageLabeled, InputLabeled, HeadingText, Button} from "./index";
import Form from "./form";
import {dataItemUpdate} from "../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../utils";
import InputLabeledAddress from "./address/form-address";
import Label from "./common/form-label";
import SlidingPane from "react-sliding-pane";
import CategoryItem from "./services/form-category-item";
import {newElementsFactory} from "../../services/data-service/data-service";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isUniversity} from "../../utils/keys";
import FormBranch from "./block/form-branch";

const FormItem = props => {

    const {index, total, level, data, section, id, invisibleItems, dataItemUpdate} = props
    const { width } = useWindowDimensions()

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });

    const [panelItem, setPanelItem] = useState(null);

    const addServiceItem = (type) => {
        setPanelItem(null)
        setState({isPaneOpen: false})

        const objToAdd = {
            value: {...newElementsFactory(type)},
            action: "add"
        }
        if (id) objToAdd.parentId = id;

        dataItemUpdate(objToAdd);
    }

    const openServiceWindow = (id, type) => {
        setPanelItem({id, type})
        setState({isPaneOpen: true})
    }

    const renderControl = (props={}, index=0) => {

        props.index = index;
        props.id = props._id;

        switch(props.control) {

            case 'address': return <InputLabeledAddress {...props}  />;
            case 'worktime': return <InputLabeled textarea mandatory initValidation {...props}  />;
            case 'contacts': return <InputLabeled textarea mandatory initValidation {...props}  />;
            case 'email': return <InputLabeled type='email' mandatory initValidation {...props}  />;
            case 'website': return <InputLabeled type='url' mandatory initValidation {...props}  />;
            case 'social': return <InputLabeled type='url' mandatory initValidation {...props}  />;
            case 'image': return <ImageLabeled {...props}  />;
            case 'text': return <InputLabeled textarea {...props}  />;
            case 'heading': return <InputLabeled mandatory initValidation {...props}  />;
            case 'headingtext': return <HeadingText {...props}  />;
            case 'filial': return data?.branches ? <FormBranch {...props} /> : <HeadingText {...props}  />;
            case 'newsbutton': return <Button redirectTo="news" {...props}  />;
            case 'servicesbutton': return <Button redirectTo="services" {...props}  />;

            case 'newsitem': return <NewsItem {...props}  />;

            case 'category': {

                return (
                    <div>
                        <div className="service-item-left">
                            <Label
                                onClick={() => openServiceWindow(props.id, 'category')}
                                className='enabledClick fcblue fs-14 td-underline'
                                id={props.id}
                                type={props.control}
                                validationPlaceholder='Введите название категории'/>
                        </div>
                        { (props.children && invisibleItems[id] !== true) &&
                        <Form level={level + 1} id={props.id} /> }
                    </div>
                )
            }
            case 'service': {
                //return <ServiceItem  {...props} />
                const labelItem = getItemById(data[section], props.id)

                return (
                    <div className="service-item-left">
                        <Label
                            onClick={() => openServiceWindow(props.id, 'service')}
                            className='enabledClick fcblue fs-14 td-underline'
                            label={labelItem.value.headingValue}
                            type={props.control}
                            validationPlaceholder='Введите название сервиса'/>
                    </div>
                )
            }

            default: return null;
        }
    }

    const dataItem =  getItemById(data[section], id);

    const styleItem = {
        paddingLeft: level * 20,
        width: '100%',
        opacity: dataItem.visible === "on" ? 1 : 0.3,
        pointerEvents: dataItem.visible === "on" ? "auto" : "none"
    }

    return (
        <div className={`form-item`} id={dataItem._id}>
            <div className='wp-100'>
                <FormItemMenu index={ index } total={ total } id={ id } />
                <div style={ styleItem }>
                    {renderControl(dataItem, index)}
                </div>
            </div>

            <SlidingPane
                isOpen={state.isPaneOpen}
                title="Услуги и сервисы"
                width={width < 450 ? '100%' : '60%'}
                subtitle=""
                onRequestClose={() => {
                    setState({ isPaneOpen: false });
                }}>
                <div className={`wp-100 ${isUniversity ? 'university' : 'city'}`}>
                    {panelItem &&
                        <div>
                            {panelItem.type === 'service' && <ServiceItem  id={panelItem.id} level={level} />}
                            {panelItem.type === 'category' && <CategoryItem callback={(type) => addServiceItem(type)}  id={panelItem.id} level={level} />}
                        </div>
                    }
                </div>

            </SlidingPane>

        </div>
    )

}


const mapStateToProps = ({ user: { data, section }, menu: {openMenuId, invisibleItems}}) => {
    return { data, section, openMenuId, invisibleItems };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FormItem);
