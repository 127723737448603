import React, {useEffect, useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import FormMap from "./form-map";
import {InputLabeled} from "../index";
import Geocode from "react-geocode";
import CheckBox from "../common/form-checkbox";
Geocode.setApiKey("AIzaSyAoHM_q2q-uO_DZ-YOl3B-1mVBdhHyV57c");
Geocode.setLanguage("ru");
Geocode.setRegion("by");
Geocode.enableDebug();

const InputLabeledAddress = props => {

    const {data, section, id, dataItemUpdate} = props;

    const [addressForMap, setAddressForMap] = useState(getItemById(data[section], id).value);

    useEffect(() => {
        setAddressForMap(getItemById(data[section], id).value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onChangeHandler = (value) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                textValue: value
            },
            action: "update"
        })
    }

    const onCoordinateHandler = (value, field) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                [field]: field !== 'custom' ? Number(value) : value
            },
            action: "update"
        })
    }


    const onLatLng = (lat,lng) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                lat: Number(lat),
                lng: Number(lng),
            },
            action: "update"
        })
    }

    const onFocusoutHandler = () => {

        const dataItem =  getItemById(data[section], id);

        if (dataItem.value?.custom) return;

        Geocode.fromAddress(dataItem.value.textValue).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                onLatLng(Number(lat), Number(lng))

                setAddressForMap({
                    address: dataItem.value.textValue,
                    lat: Number(lat),
                    lng: Number(lng)
                })

            },
            error => {
                console.error(error);
            }
        )
    }

    const dataItem =  getItemById(data[section], id);
    const checkCustomAddress = dataItem.value?.custom || false;

    console.log(dataItem)

    return (
        <div className="form-group">

            <p className='mb-10 fs-14-12'>{dataItem.label}</p>
            <div className='d-flex wrapMobile'>
                <div className='w-290 mrweb-20'>
                    <FormMap address={addressForMap} onLatLng={onLatLng} />
                </div>
                <div className='wp-100'>
                    <div className='wp-100'>
                        <InputLabeled textarea className='h-75' mandatory onBlur={onFocusoutHandler} onChange={onChangeHandler} initValue={dataItem.value.textValue} />
                    </div>
                    <div className='wp-100'>
                        <div className='d-flex-wrap-ac' style={{gap: 5}}>
                            <CheckBox checked={checkCustomAddress} callBackChange={(val) => onCoordinateHandler(val, 'custom')} />
                            <div className='fs-14'>Ввести координаты вручную</div>
                        </div>

                        <div className='wp-100 mt-20 d-flex' style={{gap: 20}}>
                            <div>
                                <div className='fs-10 mb-5'>Lat</div>
                                <InputLabeled disabled={!checkCustomAddress} onChange={(val) => onCoordinateHandler(val, 'lat')} initValue={dataItem.value?.lat || ''} />
                            </div>
                            <div>
                                <div className='fs-10 mb-5'>Lng</div>
                                <InputLabeled disabled={!checkCustomAddress} onChange={(val) => onCoordinateHandler(val, 'lng')} initValue={dataItem.value?.lng || ''} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputLabeledAddress);
