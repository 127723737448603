

const updateMenu = (state, action) => {

    if (state === undefined) {
        return {
            openMenuId: "",
            invisibleItems: {},
        }
    }


    switch (action.type) {
        case 'UPDATE_MENU':
            return {
                openMenuId: (action.payload !== state.menu.openMenuId) ? action.payload : "",
                invisibleItems: {...state.menu.invisibleItems}
            };

        case 'UPDATE_MENU_VISIBILITY':
            return {
                openMenuId: state.menu.openMenuId,
                invisibleItems: {...state.menu.invisibleItems, [action.payload.id]: action.payload.value}
            };

        default:
            return state.menu;

    }
}

export default updateMenu;
