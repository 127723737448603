import React, {Component} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";

class TextareaLabeled extends Component {

    onChangeHandler = (e) => {
        e.preventDefault();

        this.props.dataItemUpdate({
            id: this.props.id,
            value: e.target.value,
            action: "update"
        })

    }

    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);

        return (
            <div className="form-group">
                <label>{dataItem.label}</label>
                <textarea type="text" onChange={this.onChangeHandler} value={dataItem.value}/>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextareaLabeled);
