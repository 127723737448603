import store from "../../store";

const SERVER_URL = process.env.REACT_APP_BACKEND;

const getCity = () => store.getState().user.city.alias;

export default class APIService {

  dispatchError = (code) => {
    const lngError = store.getState().language.texts.resErrors;
    const errorMessage = isNaN(code) ? code : lngError[`res${code}`]
    return errorMessage || 'Error during request processing happened'
  }

  getAsyncFetch = (urladdon, requestOptions={}, method='GET', obj=null, token=null) => {

    const request = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }

    if (token) request.headers['Authorization'] = `Bearer ${token}`;
    if (obj) request.body = JSON.stringify(obj);


    /*
    return fetch(SERVER_URL + urladdon, request)
        .then(res => res.json())
        .then(res => {
          if(res.error) {
            return Promise.reject(res.error);
          }
          return res.result ? Promise.resolve(res.result) : Promise.resolve(res.message);
        })
        .catch(error => {
          return Promise.reject(error);
        })

     */

    return fetch(SERVER_URL + urladdon, requestOptions || request)
        .then(res => {
          return ([200,201].includes(res.status)) ? res.json() : Promise.reject(this.dispatchError(res.status || 400))
        })
        .then(res => {
          if(res === undefined) {
            return Promise.reject(this.dispatchError(400));
          }
          return res.result ? Promise.resolve(res.result) : Promise.resolve(res.message);
        })
        .catch(error => {
          return Promise.reject(this.dispatchError(error || 400));
        })
  }



  getUserData = async (loginCreds) => {

    return this.getAsyncFetch("auth/login", null, 'POST', {
      login: loginCreds.user,
      password: loginCreds.password
    });
  };

  getCity = async (alias) => {
    return this.getAsyncFetch(`city/${alias}`, null, 'GET');
  };

  getAdminData = async (orgId, noLog = false) => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
    };

    const noLogStr = noLog ? "?nolog=true" : ""

    return this.getAsyncFetch("organizations/" + orgId + noLogStr, requestOptions);
  };

  getCategories = async () => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch("directory", requestOptions);
  };

  getStats = async (orgId, from, to) => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`statistics/individual/${orgId}?from=${from}&to=${to}`, requestOptions);
  };

  getRequests = async () => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`request/org`, requestOptions);
  };

  getDelivery = async () => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`delivery/org`, requestOptions);
  };

  updateRequest = async (id, request) => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(request)
    };
    return this.getAsyncFetch(`request/org/` + id, requestOptions);
  };

  updateDelivery = async (id, delivery) => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(delivery)
    };
    return this.getAsyncFetch(`delivery/org/` + id, requestOptions);
  };

  getModeratorData = async (login, password) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({login, password})
    };
    return this.getAsyncFetch("organizations/moderator/", requestOptions);
  };

  getSectionName = (control) => {
    switch (control) {
      case "category": return "categories";
      case "service": return "services";
      case "newsitem": return "news";
      default: return "infosections"
    }
  }

  createNewDataItem = async (obj) => {
    const data = store.getState().user;

    obj.value.organizationId = data.data._id;

    if (obj.value.control === 'category' || obj.value.control === 'service') {
      if (obj.value.control === 'service') {
        obj.value.parentId = obj.parentId ? obj.parentId : data.data._id;
      } else {
        if (obj.parentId) obj.value.parentId = obj.parentId
      }
    } else {
      obj.value.parentId = data.data._id;
    }

    const sectionName = this.getSectionName(obj.value.control)

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
        },

      body: JSON.stringify(obj.value)
    };
    return this.getAsyncFetch(sectionName, requestOptions);
  }

  saveAllData = async () => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(data.data)
    };
    return this.getAsyncFetch("organizations/" + data.data._id, requestOptions);
  }

  saveOrganizationSettings = async (settings={}) => {

    const data = store.getState().user;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
            "organizations": [
              {
                "_id": data.data._id,
                ...settings
              }
            ]
          }
      )
    };
    return this.getAsyncFetch("organizations/root/data", requestOptions);
  }

  getMapItems = async () => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      //body: JSON.stringify(obj.value)
    };
    return this.getAsyncFetch(`mapitems/org`, requestOptions);
  }

  createMapItem = async (obj) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };
    return this.getAsyncFetch(`mapitems/org`, requestOptions);
  }

  updateMapItem = async (id, obj) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };
    return this.getAsyncFetch(`mapitems/org/${id}`, requestOptions);
  }

  deleteMapItem = async (id) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`mapitems/org/${id}`, requestOptions);
  }

  getMapRoutes = async () => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      //body: JSON.stringify(obj.value)
    };
    return this.getAsyncFetch(`maproutes/org`, requestOptions);
  }

  createMapRoute = async (obj) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };
    return this.getAsyncFetch(`maproutes/org`, requestOptions);
  }

  updateMapRoute = async (id, obj) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };
    return this.getAsyncFetch(`maproutes/org/${id}`, requestOptions);
  }

  getScheduleRegistry = async (type='groups') => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`schedule-registry?type=${type}&university=${getCity()}`, requestOptions);
  }

  updateScheduleRegistry = async (type, obj) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };
    return this.getAsyncFetch(`schedule-registry?type=${type}&university=${getCity()}`, requestOptions);
  }

  deleteMapRoute = async (id) => {
    const data = store.getState().user;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };
    return this.getAsyncFetch(`maproutes/org/${id}`, requestOptions);
  }

  getCities = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    return this.getAsyncFetch("/city", requestOptions);
  };

}
