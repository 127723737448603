import React, {Component} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import CheckBox from "./form-checkbox";

class CustomCheck extends Component {

    onChangeHandler = (e) => {
        e.preventDefault();

        const status = e.target.checked ? "on" : "off";

        e.target.checked = !e.target.checked;

        this.props.dataItemUpdate({
            id: this.props.id,
            value: status,
            action: "update"
        })

    }

    callBackChange = (checkStatus) => {

        const status = checkStatus ? "on" : "off";

        this.props.dataItemUpdate({
            id: this.props.id,
            value: status,
            action: "update"
        })
    }


    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const isChecked = dataItem.value === "on";

        return (
            <div className="custom-check">
                <CheckBox checked={isChecked} callBackChange={this.callBackChange} />
                <span>{this.props.label}</span>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCheck);