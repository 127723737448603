import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { DataGrid, ruRU, useGridApiContext } from '@mui/x-data-grid';
import {FiX} from "react-icons/fi";
import {dataItemUpdate} from "../../../../actions";
import Multiselect from 'multiselect-react-dropdown';
import './forms-reset.css'
import {v4 as uuidv4} from "uuid";

const lessonTypes = [
    {name: 'Лекция'},
    {name: 'Семинар'},
    {name: 'Практика'},
]

const secondaryList = [
    'итальянский',
    'испанский',
    'французский',
    'немецкий',
    'польский',
    'японский',
    'китайский',
    'корейский',
    'арабский',
    'турецкий',
]

const GridTeachersNames = ({ registry, updateRegistry, lessons, cafedras, streams, specializations, teachers }) => {

    const [rows, setRows] = useState(registry || [])

    function LessonsEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessons || []).map((item, index) => {return {name: item.value, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20'} style={{position: 'absolute', background: '#FFF', width: '100%' }}>
            <Multiselect placeholder={'Добавить'} showCheckbox={true}
                options={secondaryOptions}
                selectedValues={secondaryOptionsSelected}
                onSelect={(selectedList) => handleChange(null, selectedList)}
                onRemove={(selectedList) => handleChange(null, selectedList)}
                displayValue="name" // Property name to display in the dropdown options
            />
            </div>
        );
    }

    function LessonsTypesEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessonTypes || []).map((item, index) => {return {name: item.name, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20 multiselectList'} style={{position: 'absolute', background: '#FFF' }}>
                <Multiselect placeholder={'Добавить'} showCheckbox={true}
                             options={secondaryOptions}
                             selectedValues={secondaryOptionsSelected}
                             onSelect={(selectedList) => handleChange(null, selectedList)}
                             onRemove={(selectedList) => handleChange(null, selectedList)}
                             displayValue="name" // Property name to display in the dropdown options
                             style={{
                                 option: {

                                 }
                             }}
                />
            </div>
        );
    }

    const renderLessonsEditInputCell = (params) => {
        return <LessonsEditInputCell {...params} />;
    };

    const renderLessonsTypesEditInputCell = (params) => {
        return <LessonsTypesEditInputCell {...params} />;
    };


    useEffect(() => {
        setRows(registry || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(registry)])

    const addRow = () => {
        const rowsCopy = [...rows]
        rowsCopy.push({
            id: uuidv4(),
            name: 'Новая запись',
            comment: '',
        })
        updateRegistry(rowsCopy)
    }

    const deleteRow = (row) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === row.id)
        if (existedRowIndex > -1) {
            rowsCopy.splice(existedRowIndex, 1)
        }
        updateRegistry(rowsCopy)

    }

    const processRowUpdate = (newRow) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === newRow.id)
        if (existedRowIndex > -1) {
            rowsCopy[existedRowIndex] = newRow
        }
        const updatedRow = { ...newRow, isNew: false };
        updateRegistry(rowsCopy);
        return updatedRow;
    };


    const columns = [
        { field: 'name', headerName: 'Преподаватель', sortable: true, width: 200, editable: true, },
        { field: 'cafedra', headerName: 'Кафедра', sortable: true, width: 200, editable: true, type: 'singleSelect', valueOptions: cafedras.map(el => el.name) },
        { field: 'prefix', headerName: '', sortable: true, width: 250, editable: true, type: 'singleSelect',  },
        { field: 'prefix2', headerName: '', sortable: true, width: 100, editable: true, type: 'singleSelect', },
        { field: 'science_prefix', headerName: '', sortable: true, width: 100, editable: true, type: 'singleSelect', },

        { field: 'comment', headerName: 'Коммент (препод)', sortable: true, width: 300, editable: true },
        { field: 'delete', headerName: '', sortable: false, width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <FiX className='enabledClick' color='#000000' size={20} onClick={() => window.confirm('Вы уверены, что хотите удалить строку?') && deleteRow(params.row)} />
                    </div>
                );
            }
        },
    ];


    if (!Array.isArray(rows)) {
        return null
    }

    return (
        <div>
            <h3 className={'mt-20 mb-20'}>Преподаватели</h3>
            <button className={'button-link mt-10 mb-10'} onClick={() => addRow()}>Добавить</button>
            <div className='white' style={{ width: '100%', height: 600 }}>
                <div style={{ height: '100%', width: '100%', fontSize: 12 }}>
                    <DataGrid
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows.map((el, elIndex) => {
                            return {
                                ...el,
                            }
                        })}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridTeachersNames);
