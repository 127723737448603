import React from "react";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../../utils";
import TimeRow from "./form-time-row";
import {sortTime} from "../../../../utils/utils";
import InputLabeled from "../../common/form-input-labeled";

const DateTimeItem = props => {

    const {data, section, id, dataItemUpdate} = props;

    const addTimeHandler = () => {
        const dataItem =  getItemById(data[section], id);
        const value = {...dataItem.value};
        value.time.push({time: "00:00", limit:1});

        sortTime(value)

        updateReducer(value);
    }

    const dateChangeHandler = (val) => {

        const dataItem =  getItemById(data[section], id);
        const value = {...dataItem.value};

        value.date = val;
        updateReducer(value);
    }

    const callBackTimeRow = (index, action, timeObj) => {
        const dataItem =  getItemById(data[section], id);
        const value = {...dataItem.value};

        if (action === "update") {
            value.time[index] = timeObj;
        } else {
            value.time.splice(index, 1);
        }

        updateReducer(value);
    }

    const onDeleteHandler = (e) => {
        e.preventDefault();
        updateReducer(null, "delete");
    }

    const updateReducer = (value, action="update") => {

        dataItemUpdate({
            id: id,
            value: value,
            action: action
        })
    }



    const dataItem =  getItemById(data[section], id);
    const timeItems = dataItem.value.time;

    return (
        <div>
            <div className="service-heading">
                <h4>{dataItem.label}</h4>
                <p><button className="button" onClick={onDeleteHandler}>X</button></p>
            </div>

            <div className="service-item mt-10 mb-15 pb-15">
                <InputLabeled label='Дата' className='w-200' type='date' initValue={dataItem.value.date} onChange={(val) => dateChangeHandler(val)} />
                <button className="button-alt" onClick={addTimeHandler}>Добавить время</button>
                </div>
            <div className="datetime-item-timerows">
                {   timeItems &&

                    timeItems.map(
                        (item, index) => {

                            return (
                                <TimeRow key={index} index={index} callBackTimeRow={callBackTimeRow} time={item.time} limit={item.limit} />
                            )
                        }
                    )
                }
            </div>
            <button className="button-link" onClick={() => updateReducer(sortTime(dataItem.value))}>Отсортировать время</button>

        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeItem);
