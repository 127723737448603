import React, {useState} from "react";
import {connect} from "react-redux";
import {getItemById} from "../../utils";
import {dataItemUpdate} from "../../actions";
import OperationsGrid from "./operations-common/operations-grid";
import CheckBox from "../form-controls/common/form-checkbox";
import SchedulerView from "./scheduler/scheduler-view";

const OperationsContainer = props => {

    const {data, id} = props
    const [schedulerView, setSchedulerView] = useState(false)

    const dataItem = getItemById(data["services"], id);

     if (dataItem.serviceType === "service_apply") {
         if (dataItem.value === "off") return <p className='mt-20'>Запись на данный сервис не активирована</p>
         if (dataItem.serviceType === "service_apply" && dataItem.children && dataItem.children.length === 0) return <p className='mt-20'>В данном сервисе нет данных для онлайн-записи</p>
     } else {
         return null;
     }

     const { children } = dataItem;

     const schedulerData = []
     children.forEach(item => {
         if (item.childType === 'date_time' || item.childType === 'date') {
             const { date } = item.value;
             if (date) {
                 const {time} = item.value;
                 if (item.childType === 'date_time' && time && time.length > 0) {
                     time.forEach(timeItem => {
                         //const stringDateFrom = `${date}T${timeItem.time}:00.000Z`;
                         const arrTime = timeItem.time.split(':')
                         if (arrTime && arrTime.length === 2) {
                             const dateFrom = new Date(date);
                             dateFrom.setHours(arrTime[0])
                             dateFrom.setMinutes(arrTime[1])
                             const dateTo = new Date(dateFrom);
                             dateTo.setMinutes(dateTo.getMinutes() + 30);

                             const { order } = timeItem;
                             if (order && order.length > 0) {
                                 const names = order.map(item => item.person);
                                 const namesStr = names.length > 0 ? names.join(', ') : 'онлайн-запись'
                                 schedulerData.push({
                                     start: dateFrom,
                                     end: dateTo,
                                     title: namesStr,
                                     item: timeItem.order
                                 })
                             }
                         }
                     })
                 }

                 if (item.childType === 'date') {
                     const {orders} = item.value
                     const stringDateFrom = `${date}`;
                     const dateFrom = new Date(stringDateFrom);
                     dateFrom.setHours(8)
                     const dateTo = new Date(dateFrom);
                     dateTo.setMinutes(dateTo.getMinutes() + 600)
                     schedulerData.push({
                         start: dateFrom,
                         end: dateTo,
                         title: 'онлайн-запись',
                         item: orders
                     })

                 }
             }
         }
     })


    return (
        <div className="mt-20">
            <h3>{dataItem.label}</h3>
            <div className='mt-10 d-flex-ac'>
                <CheckBox checked={schedulerView} callBackChange={(val) => setSchedulerView(val)} />
                <p>В виде календаря</p>
            </div>
            <div>
                {!schedulerView && children &&
                    children.map((item, index) => <OperationsGrid key={index} id={item.id} />)
                }
                {schedulerView && schedulerData.length > 0 &&
                    <SchedulerView servicesData={schedulerData} />
                }
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationsContainer);
