import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { DataGrid, ruRU } from '@mui/x-data-grid';
import {FiX} from "react-icons/fi";
import {dataItemUpdate} from "../../../../actions";
import {v4 as uuidv4} from "uuid";

const secondaryList = [
    'итальянский',
    'испанский',
    'французский',
    'немецкий',
    'польский',
    'японский',
    'китайский',
    'корейский',
    'арабский',
    'турецкий',
    'шведский',
]

const GridLessons = ({ registry, updateRegistry }) => {

    const [rows, setRows] = useState(registry || [])

    useEffect(() => {
        setRows(registry || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(registry)])

    const addRow = () => {
        const rowsCopy = [...rows]
        rowsCopy.push({
            id: uuidv4(),
            name: 'Новая запись',
            comment: '',
        })
        updateRegistry(rowsCopy)
    }

    const deleteRow = (row) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === row.id)
        if (existedRowIndex > -1) {
            rowsCopy.splice(existedRowIndex, 1)
        }
        updateRegistry(rowsCopy)

    }

    const processRowUpdate = (newRow) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === newRow.id)
        if (existedRowIndex > -1) {
            rowsCopy[existedRowIndex] = newRow
        }
        const updatedRow = { ...newRow, isNew: false };
        updateRegistry(rowsCopy);
        return updatedRow;
    };


    const columns = [
        { field: 'name', headerName: 'Название предмета', sortable: true, width: 600, editable: true },
        { field: 'secondary', headerName: 'Язык 2', editable: true, sortable: true, width: 200, type: 'singleSelect', valueOptions: secondaryList },
        { field: 'delete', headerName: '', sortable: false, width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <FiX className='enabledClick' color='#000000' size={20} onClick={() => window.confirm('Вы уверены, что хотите удалить строку?') && deleteRow(params.row)} />
                    </div>
                );
            }
        },
    ];


    if (!Array.isArray(rows)) {
        return null
    }

    return (
        <div>
            <h3 className={'mt-20 mb-20'}>Предметы</h3>
            <button className={'button-link mt-10 mb-10'} onClick={() => addRow()}>Добавить</button>
            <div className='white' style={{ width: '100%', height: 600 }}>
                <div style={{ height: '100%', width: '100%', fontSize: 12 }}>
                    <DataGrid
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows.map((el, elIndex) => {
                            return {
                                ...el,
                            }
                        })}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridLessons);
