import updateData from "./updateUserData";
import updateMenu from "./updateMenuStatus";
import updateLanguage from "./updateLngData";

const reducer = (state, action) => {
    return {
        user: updateData(state, action),
        menu: updateMenu(state, action),
        language: updateLanguage(state, action)
    };
};

export default reducer;
