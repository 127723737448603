import React from 'react';

import chart from "../../../assets/landing/mc-chart.svg"


const City = () => {

    return (
        <div id="city" className="landing-gray-box mt-50">
            <div className="landing-container">
                <div className="d-flex-sb wrapMobile">
                    <div className="landing-gray-box-image wp-100">
                        <img src={chart} style={{minWidth: 260, width: "90%"}} alt="phone" />
                    </div>

                    <div className="mlweb-30 wp-100 p-30">
                        <div>
                            <h1 className="mt-50">Для города</h1>
                            <p className="mt-30">Платформа «Мой Город» разрабатывается в Республике Беларусь для упрощения взаимодействия между горожанами и городской инфраструктурой, повышения эффективности и скорости этого взаимодействия с использованием цифровых технологий</p>
                            <p className="mt-30">Платформа создается для улучшения качества жизни белорусских городов, повышения комфорта с помощью электронных сервисов и различных технологических новшеств.</p>
                        </div>
                        <div className="mt-30 heading-font">
                            <h3>Плюсы подключения</h3>
                            <ul className="mt-20">
                                <li>Превращение в "Умный город" в краткие сроки</li>
                                <li>Экономия за счет использования одной платформы</li>
                                <li>Круглосуточная поддержка</li>
                            </ul>
                        </div>
                        <div className="mt-30">
                            <a href="https://forms.gle/TSjDctntsWoRb6Q7A" target="_blank" rel="noopener noreferrer"><button className="button">ПОДКЛЮЧИТЬСЯ</button></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );

}


export default City
