import React from "react";
import {saveAllData} from "../../actions";
import {connect} from "react-redux";

const SaveButton = props => {

    const {saveNeeded, saveAllData, texts} = props
    const {saveComponent: { saveText }} = texts.components

    const styleItem = {
        opacity: saveNeeded ? 1 : 0.1,
        pointerEvents: saveNeeded ? "auto" : "none"
    }

    return (
        <div style={styleItem}>
            <button disabled={!saveNeeded} onClick={saveAllData} className="button">{saveText}</button>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, saveNeeded }, language: {texts}}) => {
    return { data, section, saveNeeded, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveAllData: saveAllData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton);
