import React from 'react';
import {updateSection} from "../../actions";
import {connect} from "react-redux";

const Tab = ({tabData, selected, updateSection}) => {

    const tabClass = (tabData.name === selected) ? "tab selected-tab" : "tab";

    function handleClick() {
        updateSection(tabData.name)
    }

    return (
        <div className={tabClass}  onClick={handleClick}>
            <label>{tabData.label}</label>
        </div>
    );
}

const mapStateToProps = ({ user: { data }}) => {
    return { data };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSection: updateSection(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab);