import React from 'react';
import Tab from "./Tab";
import {connect} from "react-redux";

const Tabbar = props => {

    const {data, section} = props
    const tabs = [...data.sections, {name:"operations", label:"Личный кабинет"}]

    return (

            <div className="tabbar wrapMobile">
            {
                tabs.map(
                    (item, index) => <Tab key={index} tabData={item} selected={section} />
                )
            }
        </div>
    );
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

export default connect(mapStateToProps)(Tabbar);
