import React, {Component} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import ServicePreviewContainer from "./service-preview-container";


class ServicePreviewItem extends Component {


    getJSXText = (text, type) => {

        if (text === "") return null

        switch(type) {

            case 'text': return <p>{text}</p>
            case 'heading': return <h2>{text}</h2>
            case 'image': return  <p><img width="100%" alt="service" src={text} /></p>
            case 'url': return  <p><a href={text}>Переход на сайт</a></p>
            case 'price': return  <p>Цена: <strong>{text}р</strong></p>

            default: return null;
        }
    }



    render () {

        const data = getItemById(this.props.data[this.props.section], this.props.id)
        const text = data.value
        const children = data.children

        return (
            <div className="mt-30">

                {text.imageValue && this.getJSXText(text.imageValue, "image")}
                {text.headingValue && this.getJSXText(text.headingValue, "heading")}
                {text.textValue && this.getJSXText(text.textValue, "text")}
                {text.priceValue && this.getJSXText(text.priceValue, "price")}
                {text.linkValue && this.getJSXText(text.linkValue, "url")}

                <div>
                    {children &&

                    children.map(
                        (item) => {

                            return (
                               <ServicePreviewContainer key={item.id} id={item.id} />
                            )
                        }
                    )
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreviewItem);