import React, {Component} from "react";

class CheckBox extends Component {

    onClickHandler = (e) => {
        e.preventDefault();

        this.props.callBackChange(!this.props.checked, e)
    }

    render () {

        const checked = this.props.checked;
        const styleNameStr = checked ? "checkbox-container checkbox-checked" : "checkbox-container"

        return (
            <div onClick={this.onClickHandler} className={styleNameStr}>
                <div className="checkbox-slider" />
            </div>
        )
    }
}

export default CheckBox;
