import React, {useEffect, useState} from "react";
import {getItemById} from "../../../utils";
import {connect} from "react-redux";

import {FiFolder, FiCalendar} from 'react-icons/fi'

const Label = props =>  {

    const { ico, type, label, data, section, id, className, onClick, validationPlaceholder } = props;

    const [value, setValue] = useState(label || '');

    useEffect(() => {
        setValue(label || '');
    }, [label]);

    const dataItem =  getItemById(data[section], id);
    const valueToDisplay = !id ? value : dataItem.value;
    const validationError = valueToDisplay ? {} : {color: '#ff0000'}

    return (
        <div className='d-flex-ac form-label' onClick={() => onClick && onClick()}>
            {ico && ico}
            {type &&
            <div>
                {type === 'category' && <FiFolder size={18} className='mr-10' />}
                {type === 'service' && <FiCalendar size={18} className='mr-10' />}
            </div>
            }
            <p className={className} style={validationError}>{valueToDisplay || validationPlaceholder || ''}</p>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }, language: { texts }}) => {
    return { data, section, texts };
};

export default connect(mapStateToProps)(Label);
