import React, {Component} from "react";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../../utils";
import {getLabels} from "../../../../labels";
import TimeRow from "./form-time-row";

const DAYS = [
    {label:"понедельник"},
    {label:"вторник"},
    {label:"среда"},
    {label:"четверг"},
    {label:"пятница"},
    {label:"суббота"},
    {label:"воскресенье"}
]

class DateRecurrentItem extends Component {


    addTimeHandler = (index) => {
        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const value = {...dataItem.value};
        value.time.push({time: "00:00", limit:1});

        this.updateReducer(value);
    }

    dayChangeHandler = (e) => {

        e.preventDefault();

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const value = {...dataItem.value};
        value.day = DAYS[e.target.selectedIndex].label;

        this.updateReducer(value);
    }

    callBackTimeRow = (index, action, timeObj) => {
        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const value = {...dataItem.value};

        if (action === "update") {
            value.time[index] = timeObj;
        } else {
            value.time.splice(index, 1);
        }

        this.updateReducer(value);
    }

    onDeleteHandler = (e) => {
        e.preventDefault();

        this.updateReducer(null, "delete");
    }

    updateReducer = (value, action="update") => {
        this.props.dataItemUpdate({
            id: this.props.id,
            value: value,
            action: action
        })
    }

    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const day = dataItem.value.day;

        const timeItems = dataItem.value.time;

        return (
            <div>
                <div className="service-heading">
                    <h4>{dataItem.label}</h4>
                    <p><button className="button" onClick={this.onDeleteHandler}>X</button></p>
                </div>

                <div className="service-item mt-10 mb-15 pb-15">
                    <label className="mr-5">{getLabels().pickers.date}{":"}</label>
                    <select value={day} className="w-160 mr-10" name="datetime_select" onChange={this.dayChangeHandler}>
                        {DAYS.map(
                            (item, index) => <option key={index}>{item.label}</option>
                        )}
                    </select>
                    <button className="button-alt" onClick={this.addTimeHandler}>Добавить</button>
                </div>
                <div className="datetime-item-timerows">
                    {   timeItems &&

                        timeItems.map(
                            (item, index) => {

                                return (
                                    <TimeRow key={index} index={index} callBackTimeRow={this.callBackTimeRow} time={item.time} limit={item.limit} />
                                )
                            }
                        )
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRecurrentItem);
