import React, {useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CheckBox from "../common/form-checkbox";
import {ImageLabeled, InputLabeled} from "../index";
import SlidingPane from "react-sliding-pane";
import {getTextDate} from "../../../utils/dates";
import SaveButton from "../../savebutton/save-button";
import FormNewsPoll from "./form-news-poll";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {isUniversity, theme} from "../../../utils/keys";

const NewsItem = props => {

    const { width } = useWindowDimensions();

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });


    const openNewsWindow = () => {
        setState({isPaneOpen: true})
    }

    const {data, section, id, texts, dataItemUpdate, cities} = props;
    const {newsComponent: {
        newsHeadingText,
        newsImageText,
        newsDeleteText,
        newsContentText,
        newsDateText,
        newsExpDateText,
        newsExpTimeText,
        newsMainText,
        newsSpecialText,
        featuredText,
        pollMainText,
        menuMainText,
        newsOffersText,
        vacancyOffersText,
        newsTimeText,
        newsTitleText,
        newsUrlText,
        newsPreviewHeadingText,
        newsPreviewContentText
    }} = texts.components

    const [expirationExpanded, setExpirationExpanded] = useState(false)

    const onChangeHandler = (type, value) => {
        const dataItem =  getItemById(data[section], id);
        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                [type]: value
            },
            action: "update"
        })
    }

    const dataItem =  getItemById(data[section], id);

    //const isNewsMaker = data._id === "5f579d787c45b550b060e2da" || data._id === "5f575ad95639aa469c83ac09" || data.mainCategory === 'admin';
    const isNewsMaker = data.type === "news" || data.type === "power" || data.mainCategory === 'admin';

    const {headingValue, textValue, imageValue, linkValue, date, time, main, offers, vacancies, menu, expDate, expTime, poll, special, pollData, pollResults, city=data.city, featured=false} = dataItem.value;
    const isSpecial = data.type === 'special' || data.type === "power" || data.mainCategory === 'admin'
    const isMenu = data.type === "power"

    const descriptions = [];
    main && descriptions.push(`${newsMainText}`)
    offers && descriptions.push(newsOffersText)

    const previewStyle = (!headingValue || !textValue) ? 'errorBorder' : ''

    const pollResultsArray = [];
    if (pollResults?.options) {
        Object.entries(pollResults.options).forEach(([key, value]) => pollResultsArray.push(<p key={key} className='mt-5 fs-14-12'>{`${key}: ${value}`}</p>));
    }

    return (
        <div className='mt-10'>
            <div className={previewStyle}>
                <div className='d-flex-ac enabledClick' onClick={() => openNewsWindow()}>
                    {imageValue &&
                    <div className='mr-20'>
                        <img className='image-preview' src={imageValue} alt='news' />
                    </div>
                    }
                    <div className='mr-20'>
                        <p className='fcblue fs-14'><strong>{headingValue || newsPreviewHeadingText}</strong></p>
                        <p className='mt-6 fs-12'>{textValue ? `${textValue.substring(0,30)}${textValue.length > 30 ? '...' : ''}` : newsPreviewContentText}</p>
                        <p className='mt-8 fs-10 fcgray'>{date ? `${getTextDate(date)}` : ''}</p>
                        <p className='mt-6 fs-10 fcgray tag-border'>{descriptions.length > 0 ? `${descriptions.join(' | ')}` : newsHeadingText}</p>
                    </div>

                </div>
            </div>

            <div className='separator1px mt-20' />

            <SlidingPane
                isOpen={state.isPaneOpen}
                title="Назад к новостям"
                width={width < 450 ? '100%' : '60%'}
                subtitle=""
                onRequestClose={() => {
                    setState({ isPaneOpen: false });
                }}>

                <div className={`wp-100 ${isUniversity ? 'university' : 'city'}`}>
                    <div className='d-flex-ac mb-15'>
                        <h4>{headingValue || newsHeadingText}</h4>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={newsTitleText} mandatory initValidation initValue={headingValue}
                                      onChange={(value) => onChangeHandler('headingValue', value)}/>
                    </div>

                    <div className='d-flex wrapMobile'>
                        <div className='d-flex mrweb-30'>
                            <div className='mr-20'>
                                <InputLabeled mandatory initValidation label={newsDateText} className="w-160" type='date'
                                              onChange={(value) => onChangeHandler('date', value)} initValue={date}/>
                            </div>
                            <div>
                                <InputLabeled label={newsTimeText} className="w-100" type='time'
                                              onChange={(value) => onChangeHandler('time', value)} initValue={time}/>
                            </div>
                        </div>
                        <div className='d-flex wrapMobile mb-20'>
                            {isNewsMaker &&
                            <div className='mr-20 mbmob-10'>
                                <p className='fs-14 mb-10'>{newsMainText}</p>
                                <CheckBox checked={main || false}
                                          callBackChange={(value) => onChangeHandler('main', value)}/>
                            </div>
                            }
                            {isSpecial &&
                            <div className='mr-20 mbmob-10'>
                                <p className='fs-14 mb-10'>{newsSpecialText}</p>
                                <CheckBox checked={special || false}
                                          callBackChange={(value) => onChangeHandler('special', value)}/>
                            </div>
                            }
                            {isNewsMaker &&
                            <div className='mr-20 mbmob-10'>
                                <p className='fs-14 mb-10'>{pollMainText}</p>
                                <CheckBox checked={poll || false}
                                          callBackChange={(value) => onChangeHandler('poll', value)}/>
                            </div>
                            }
                            {isMenu && isUniversity &&
                                <div className='mr-20 mbmob-10'>
                                    <p className='fs-14 mb-10'>{menuMainText}</p>
                                    <CheckBox checked={menu || false}
                                              callBackChange={(value) => onChangeHandler('menu', value)}/>
                                </div>
                            }
                            {
                                <div className='mr-20 mbmob-10'>
                                    <p className='fs-14 mb-10'>{featuredText}</p>
                                    <CheckBox checked={featured || false}
                                              callBackChange={(value) => onChangeHandler('featured', value)}/>
                                </div>
                            }
                            <div className='mr-20 mbmob-10'>
                                <p className='fs-14 mb-10'>{newsOffersText}</p>
                                <CheckBox checked={offers || false}
                                          callBackChange={(value) => onChangeHandler('offers', value)}/>
                            </div>
                            <div className='mr-20 mbmob-10'>
                                <p className='fs-14 mb-10'>{vacancyOffersText}</p>
                                <CheckBox checked={vacancies || false}
                                          callBackChange={(value) => onChangeHandler('vacancies', value)}/>
                            </div>
                            <div className='mbmob-10'>
                                <p className='fs-14 mb-10'>{newsDeleteText}</p>
                                <CheckBox checked={expirationExpanded}
                                          callBackChange={() => setExpirationExpanded(!expirationExpanded)}/>
                            </div>
                        </div>
                    </div>
                    {poll &&
                    <div className='d-flex mt-10'>
                        <div>
                            <FormNewsPoll pollData={pollData} callback={(value) => onChangeHandler('pollData', value)} />
                        </div>
                    </div>
                    }
                    {pollResults &&
                    <div className='mt-20 mb-20'>
                        <p>Результаты опроса:</p>
                        <div className='mt-10'>
                            { pollResultsArray }
                        </div>
                    </div>
                    }
                    {(main || expirationExpanded) &&
                    <div className='d-flex wrapMobile'>
                        {expirationExpanded &&
                        <div className='d-flex mrweb-30'>
                            <div className='mr-10'>
                                <InputLabeled label={newsExpDateText} className="w-160" type='date'
                                              onChange={(value) => onChangeHandler('expDate', value)} initValue={expDate}/>
                            </div>
                            <div>
                                <InputLabeled label={newsExpTimeText} className="w-100" type='time'
                                              onChange={(value) => onChangeHandler('expTime', value)} initValue={expTime}/>
                            </div>
                        </div>
                        }
                    </div>
                    }
                    <div className='mt-10'>
                        <InputLabeled mandatory initValidation textarea label={newsContentText}
                                      onChange={(value) => onChangeHandler('textValue', value)} initValue={textValue}/>
                    </div>
                    {data.branches && <div className='mt-10 mb-20'>
                        <select value={city} onChange={e=>onChangeHandler('city', e.target.value, true)}>
                            {[{alias: 'all', name: '* Все города'}, ...(cities || [])].map((item, index) => <option key={index} value={item.alias}>{item.name}</option>)}
                        </select>
                    </div>}
                    <div>
                        <ImageLabeled heading={newsImageText} initValue={imageValue}
                                      onChange={(value) => onChangeHandler('imageValue', value)} local/>
                    </div>
                    <div>
                        <InputLabeled type="url" label={newsUrlText} initValue={linkValue}
                                      onChange={(value) => onChangeHandler('linkValue', value)}/>
                    </div>
                    <div className='separator1px mt-10' />
                    <div className='mt-10 d-flex-end'>
                        <SaveButton />
                    </div>
                </div>

            </SlidingPane>
        </div>
    )
}

const mapStateToProps = ({ user: { data, section, cities }, language: {texts}}) => {
    return { data, section, texts, cities };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsItem);
