import React from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import {InputLabeled} from "../index";
import SaveButton from "../../savebutton/save-button";

const CategoryItem = props => {

    const {data, section, id, level, dataItemUpdate, callback } = props
    const {serviceDirectoryComponent: {directoryHeadingText}} = props.texts.components

    const onChangeHandler = (value) => {
        dataItemUpdate({
            id: id,
            value: value,
            action: "update"
        })
    }

    const dataItem =  getItemById(data[section], id);

    const isEmpty = dataItem.children.length === 0;
    const firstElement = !isEmpty ? dataItem.children[0].control : null;

    return (
        <div>
            <div>
                <InputLabeled label={directoryHeadingText} mandatory initValidation initValue={dataItem.value}
                              onChange={(value) => onChangeHandler(value)}/>
            </div>
            <div className='mt-10'>
                {level === 0 && (!firstElement || firstElement === 'category') && <button className='button-alt mr-15' onClick={() => callback('category')}>Добавить подкатегорию</button>}
                {(!firstElement || firstElement === 'service') && <button className='button-alt' onClick={() => callback('service')}>Добавить сервис</button>}
            </div>
           <div className='separator1px mt-10' />
            <div className='mt-10 d-flex-end'>
                <SaveButton />
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }, language: {texts}}) => {
    return { data, section, texts };
};


const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem);
