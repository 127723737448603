import React from "react";
import {DIRECTION_DOWN, DIRECTION_UP} from "../../constants";
import {dataOrderUpdate, dataItemUpdate, menuUpdate, menuVisibiltyUpdate} from "../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../utils";

import {FiMenu, FiX, FiEyeOff, FiEye, FiTrash, FiChevronUp, FiChevronDown} from "react-icons/fi";

const FormItemMenu = props => {

    const { index, data, section, id, total, openMenuId, dataOrderUpdate, dataItemUpdate, menuUpdate } = props;
    const {formItemMenu: {hideText, showText, deleteText, upText, downText}} = props.texts.components;

    const handleClickDirection = (direction)  => {

        const newIndex = (direction === DIRECTION_UP) ? index - 1 : index + 1;

        dataOrderUpdate({
            id: id,
            index:index,
            direction: newIndex
        });

    }

    const handleClickDelete = ()  => {
        dataItemUpdate({
            id: id,
            action: "delete"
        })

    }

    const handleClickHide = ()  => {

        const dataItem =  getItemById(data[section], id);
        const visible = dataItem.visible === "on" ? "off" : "on";

        dataItemUpdate({
            id: id,
            visible: visible,
            action: "update"
        })

        menuUpdate(id);
    }

    const handleClickMenu = ()  => {
        menuUpdate(id);
    }

    const dataItem =  getItemById(data[section], id);

    const isVisible = openMenuId === id;
    const isHidden = dataItem.visible === "off"

    return (
        <div className='form-item-menu-container'>
            <div className="form-item-left">
                {!isVisible &&
                <FiMenu className='btn' onClick={handleClickMenu} size={18} color='#32B9F1' />
                }
                {isVisible &&
                <FiX className='btn' onClick={handleClickMenu} size={18} color='#32B9F1' />
                }

            </div>
            { isVisible &&
            <div className="form-item-right" >
                <div className='d-flex-ac h-30'>
                    <div className='d-flex-ac mr-10'>
                        {!isHidden &&
                        <FiEyeOff size={12} color='#32B9F1'/> }
                        {isHidden &&
                        <FiEye size={12} color='#32B9F1'/> }
                        <p className="button-link-clean" style={{marginLeft: -5}} onClick={handleClickHide}>{isHidden ? showText : hideText}</p>
                    </div>
                    <div className='d-flex-ac'>
                        <FiTrash size={12} color='#32B9F1'/>
                        <p className="button-link-clean" style={{marginLeft: -5}} onClick={handleClickDelete}>{deleteText}</p>
                    </div>
                </div>
                <div className='d-flex-ac h-30'>
                    {(index > 0) &&
                    <div className='d-flex-ac'>
                        <FiChevronUp size={12} color='#32B9F1'/>
                        <p className="button-link-clean" style={{marginLeft: -6}} onClick={(e) => handleClickDirection(DIRECTION_UP)}>{upText}</p>
                    </div>
                    }

                    {(index < total) &&
                    <div className='d-flex-ac'>
                        <FiChevronDown size={12} color='#32B9F1'/>
                        <p className="button-link-clean" style={{marginLeft: -6}} onClick={(e) => handleClickDirection(DIRECTION_DOWN)}>{downText}</p>
                    </div>
                    }
                </div>

            </div>
            }
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }, menu: {openMenuId, invisibleItems}, language: {texts}}) => {
    return { data, section, openMenuId, invisibleItems, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataOrderUpdate: dataOrderUpdate(dispatch),
        dataItemUpdate: dataItemUpdate(dispatch),
        menuUpdate: menuUpdate(dispatch),
        menuVisibiltyUpdate: menuVisibiltyUpdate(dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FormItemMenu);
