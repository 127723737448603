import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {fetchDelivery} from "../../../actions";
import DeliveryItem from "./delivery-item";

const activeStatuses = ['created', 'taken']
const completedStatuses = ['resolved', 'rejected']

const REFRESH_INTERVAL = 30 * 1000;

const Delivery = props => {

    const {fetchDelivery,  delivery} = props
    const [page, setPage] = useState(0)

    useEffect(() => {
        fetchDelivery();
        let interval = setInterval(() => {
            fetchDelivery();
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let filteredDelivery = delivery.filter(el => (page ? completedStatuses : activeStatuses).includes(el.status))

    return (
        <div>
            <h4>Доставка</h4>
            <div className='mt-20'>
                <select className='w-200' value={page} onChange={e => setPage(Number(e.target.value))}>
                    <option value={0}>Активные доставки</option>
                    <option value={1}>Завершенные доставки</option>
                </select>
            </div>
            <div className='mt-20'>
                <p className='td-strong fs-14'>Список доставок:</p>
            </div>
            <div className='mt-20'>
                {filteredDelivery.map((item, index) => <DeliveryItem key={index} item={item} />)}

            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, delivery }}) => {
    return { data, section, delivery };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDelivery: fetchDelivery(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
