import React, {useState, useEffect} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CheckBox from "../common/form-checkbox";
import {ImageLabeled, InputLabeled} from "../index";
import SlidingPane from "react-sliding-pane";
import {getTextDate} from "../../../utils/dates";
import SaveButton from "../../savebutton/save-button";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {isUniversity, theme} from "../../../utils/keys";
import APIService from "../../../services/api-service";
import {FiX} from "react-icons/fi";

const apiService = new APIService()

const FormSightRoute = props => {

    const { width } = useWindowDimensions();

    const [mapItems, setMapItems] = useState([])
    const [mapItemsHash, setMapItemsHash] = useState({})
    const [mapItemsListSelected, setMapItemsListSelected] = useState(null)
    const [mapRoutes, setMapRoutes] = useState([])
    const [currentItem, setCurrentItem] = useState(null)

    const loadMapItems = async () => {
        try {
            const result = await apiService.getMapItems()
            if (result) {
                setMapItems(result)
                if (result.length > 0) {
                    setMapItemsListSelected(result[0]._id)

                    const hash = {}
                    result.forEach(el => {
                        hash[el._id] = el
                    })
                    setMapItemsHash(hash)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadMapRoutes = async () => {
        try {
            const result = await apiService.getMapRoutes()
            if (result) {
                setMapRoutes(result)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const createMapRoute = async () => {
        try {
            const result = await apiService.createMapRoute({
                title: 'Новый маршрут',
                description: 'Описание маршрута'
            })
            if (result) {
                setMapRoutes(result)
                window.alert('Новый маршрут добавлен в конце списка')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const updateMapRoute = async () => {
        try {
            const objToProceed = {...currentItem}
            delete objToProceed._id
            const result = await apiService.updateMapRoute(currentItem._id, {
                ...objToProceed,
            })
            if (result) {
                setMapRoutes(result)
                window.alert('Информация обновлена успешно')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteMapRoute = async () => {
        try {
            const result = await apiService.deleteMapRoute(currentItem._id)
            if (result) {
                setMapRoutes(result)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        loadMapItems()
        loadMapRoutes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentItem) {
            const exists = mapItems.find(el => el._id === currentItem._id)
            if (exists) {
                setCurrentItem(exists)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(mapItems)])

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });


    const openNewsWindow = (item) => {
        setCurrentItem(item)
        setState({isPaneOpen: true})
    }

    const {data, section, id, texts, dataItemUpdate} = props;
    const {newsComponent: {
        newsHeadingText,
        newsImageText,
        newsDeleteText,
        newsContentText,
        newsDateText,
        newsExpDateText,
        newsExpTimeText,
        newsMainText,
        newsSpecialText,
        pollMainText,
        menuMainText,
        newsOffersText,
        vacancyOffersText,
        newsTimeText,
        newsTitleText,
        newsUrlText,
        newsPreviewHeadingText,
        newsPreviewContentText
    }} = texts.components

    const [expirationExpanded, setExpirationExpanded] = useState(false)

    const onChangeHandler = (type, value) => {

        if (currentItem) {
            const copyItem = {...currentItem}
            copyItem[type] = value
            setCurrentItem(copyItem)
        }
    }

    const mapItemsList = (mapItems || []).map(el => {
        return {
            title: el.title,
            _id: el._id
        }
    })

    const onAddMapItemHandler = (value) => {
        const currentMapItems = currentItem?.mapItems || []
        currentMapItems.push(value)
        onChangeHandler('mapItems', currentMapItems)
    }

    const onDeleteMapItemHandler = (index) => {
        const currentMapItems = currentItem?.mapItems || []
        currentMapItems.splice(index, 1)
        onChangeHandler('mapItems', currentMapItems)
    }

    const mapsItemsSelected = (currentItem?.mapItems || []).map(el => mapItemsHash[el]?.title)

    return (
        <div>
            <div>
                <button className={'button'} onClick={() => createMapRoute()}>Добавить маршрут</button>
                <div className={'separator1px mt-10'} />
            </div>
            {mapRoutes.map((mapItem, mapItemIndex) => {
                const {title, description, image} = mapItem
                return <div key={mapItemIndex}>
                    <div className='mt-10 d-flex-ac enabledClick' onClick={() => openNewsWindow(mapItem)}>
                        {image &&
                            <div className='mr-20'>
                                <img className='image-preview' src={image} alt='news' />
                            </div>
                        }
                        <div className='mr-20'>
                            <p className='fcblue fs-14'><strong>{title || ''}</strong></p>
                            <p className='mt-6 fs-12'>{description || ''}</p>
                        </div>

                    </div>
                    <div className={'separator1px mt-10'} />
                </div>
            })
            }

            <SlidingPane
                isOpen={state.isPaneOpen}
                title="Назад к достопримечательностям"
                width={width < 450 ? '100%' : '60%'}
                subtitle=""
                onRequestClose={() => {
                    setCurrentItem(null)
                    setState({ isPaneOpen: false });
                }}>

                {currentItem && <div className={`wp-100 ${isUniversity ? 'university' : 'city'}`}>
                    <div className='d-flex-ac mb-15'>
                        <h4>{currentItem.title || ''}</h4>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'Название маршрута'} mandatory initValidation initValue={currentItem.title || ''}
                                      onChange={(value) => onChangeHandler('title', value)}/>
                    </div>

                    <div className='mt-10'>
                        <InputLabeled mandatory initValidation textarea label={'Описание маршрута'}
                                      onChange={(value) => onChangeHandler('description', value)} initValue={currentItem.description || ''}/>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'Адрес'} mandatory initValidation initValue={currentItem.address || ''}
                                      onChange={(value) => onChangeHandler('address', value)}/>
                    </div>
                    <div className='mt-10'>
                        <InputLabeled mandatory initValidation textarea label={'Контакты'}
                                      onChange={(value) => onChangeHandler('contacts', value)} initValue={currentItem.contacts || ''}/>
                    </div>
                    <div className='mt-10 pl-15 wp-100'>
                        <p className={'mb-10'}>Достопримечательности</p>
                        <select style={{width: '80%'}} onChange={(e) => setMapItemsListSelected(e.target.value)} value={ mapItemsListSelected || ''}>
                            {mapItemsList.map((item, index) =>
                                <option key={index} value={item._id}>{item.title}</option>
                            )}
                        </select> <button className={'button'} onClick={() => onAddMapItemHandler(mapItemsListSelected)}>Добавить</button>
                        <div className='mt-20 wp-100'>
                            {mapsItemsSelected.map((item, index) =>
                                <div key={index}>
                                    <div className={'separator1px mt-20 mb-20'} />
                                    <div className={'mb-10 mt-10 d-flex-wrap-sb-ac wp-100'} style={{gap: 10}}>
                                        {item}
                                        <FiX className={'enabledClick'} onClick={() => onDeleteMapItemHandler(index)} />
                                    </div>
                                </div> )}
                        </div>
                    </div>

                    <div className={'separator1px mt-20'} />

                    <div className="form-group mt-20">
                        <p className={'mb-10'}>Участник программы "Васильковый край"</p>
                        <CheckBox checked={currentItem?.isCornflowerProgram || false}
                                  callBackChange={(value) => onChangeHandler('isCornflowerProgram', value)}/>
                    </div>

                    <div>
                        <ImageLabeled heading={newsImageText} initValue={currentItem.image || ''}
                                      onChange={(value) => onChangeHandler('image', value)} local/>
                    </div>

                    <div className='separator1px mt-10' />
                    <div className='mt-10 d-flex-sb'>
                        <button className={'button-link'} onClick={() => window.confirm('Вы уверены, что хотите удалить достопримечательность?') && deleteMapRoute()}>Удалить</button>
                        <button className={'button'} onClick={() => updateMapRoute()}>Обновить</button>
                        {/*<SaveButton />*/}
                    </div>
                </div>}

            </SlidingPane>
        </div>
    )
}

const mapStateToProps = ({ user: { data, section }, language: {texts}}) => {
    return { data, section, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSightRoute);
