export function getLabels(lng="ru") {
    switch (lng) {
        case "ru":
            return {
                formItemMenu: {
                    menuOpenLabel: "Меню",
                    menuCloseLabel: "Закрыть",
                    menuExpandLabel: "Развернуть",
                    menuCollapseLabel: "Свернуть",
                    btnUpLabel: "Поднять выше",
                    btnDownLabel: "Опустить ниже",
                    btnDeleteLabel: "Удалить",
                    btnHideLabel: "Скрыть",
                    btnShowLabel: "Показать",
                },
                pickers: {
                    date: "Дата",
                    time: "Время",
                    limit: "Лимит"
                }
            }
        default: return null;
    }
}