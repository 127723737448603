import React, {useState, useEffect} from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const SelectButton = props => {

    const [selectedIndex, setSelectedIndex] = useState(0)
    const {elements, operations, disabled, callBackSelectClick, style} = props
    const {width} = useWindowDimensions();

    useEffect(() => {
        setSelectedIndex(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements]);


    const selectChangeHandler = (e) => {
        e.preventDefault();

        setSelectedIndex(e.target.selectedIndex)

        const isOperations = operations === true;
        if (isOperations) {
            callBackUpdate(e.target.selectedIndex)
        }

    }

    const onClickHandler = (e) => {
        if (e) e.preventDefault();
        callBackUpdate(selectedIndex)
    }

    const callBackUpdate = (index) => {
        const obj = elements[index];

        callBackSelectClick(index, obj)
    }

    const isOperations = operations === true;
    const styleName = isOperations ? "w-290 mr-10" : "w-180 mr-10";

    return (
        <div>
            <select disabled={ disabled } className={ styleName } style={style} onChange={(e) => selectChangeHandler(e)}>
                {elements.map(
                    (item, index) => <option key={index}>{isOperations ? item.value.headingValue : item.label}</option>
                )}
            </select>
            {
                !isOperations && <button className="button-alt" name="btnAdd" onClick={(e) => onClickHandler(e)}>{width < 450 ? '+' : 'Добавить'}</button>
            }
        </div>
    )

}

export default SelectButton;
