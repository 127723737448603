import React from 'react';
import Header from "./header";
import Citizens from "./citizens";
import Organizations from "./organizations";
import City from "./city";
import Footer from "./footer";

const Landing = () => {

    return (
        <div className="landing-wrapper">
            <div className="separator" />
            <Header />
            <Citizens />
            <Organizations />
            <City />
            <Footer />
        </div>
    );

}


export default Landing
