import React, {useState} from 'react';
import { connect } from 'react-redux';
import { fetchUser } from '../../actions';
import CheckBox from "../form-controls/common/form-checkbox";
import {Redirect} from "react-router";
import {InputLabeled} from "../form-controls";

import { isFormValid } from "../../utils/validators";

const LoginPage = props => {

    const [login, setLogin] = useState(localStorage.getItem('user') || '')
    const [password, setPassword] = useState(localStorage.getItem('pass') || '')
    const [saveLoginPass, setSaveLoginPass] = useState(localStorage.getItem('rememberMe') === 'true' || false)

    const { loginScreen: { loginText, loginNote, passText, saveMeText } } = props.texts.screens;
    const { data, validation, fetchUser } = props;

    const isValidated = isFormValid(validation['login']);

    // Submit username and password and store them in localStorage if appropriate checkbox set
    const submitHandler = () => {
        if (!isValidated) return;

        localStorage.setItem('rememberMe', saveLoginPass.toString());
        localStorage.setItem('user', saveLoginPass ? login : '');
        localStorage.setItem('pass', saveLoginPass ? password : '');

        fetchUser({user: login, password: password});
    }

    if (data.mainLabel) {
        return <Redirect to='/admin' />
    }

    return (

        <div className="login-area">
            <div className="form-group">
                <InputLabeled
                    submit={submitHandler}
                    mandatory
                    form='login'
                    label={loginText}
                    initValue={login}
                    onChange={setLogin} />
            </div>
            <div className="form-group">
                <InputLabeled
                    submit={submitHandler}
                    mandatory
                    form='login'
                    type='password'
                    label={passText}
                    initValue={password}
                    onChange={setPassword} />
                <small>{loginNote}</small>
            </div>
            <div>
                <div className="d-flex-ac">
                    <CheckBox checked={saveLoginPass} callBackChange={(val) => setSaveLoginPass(val)} />
                    <p className='fs-14-12'>{saveMeText}</p>
                </div>
            </div>
            <div className='separator1px mt-20' />
            <div className="mt-20">
                <div className='d-flex-wrap-sb-ac'>
                    <button className={`button ${isValidated ? 'enabledClick' : 'disabledClick'}`} onClick={submitHandler}>Войти</button>
                    <button className="button-link fs-12" onClick={console.log}>Подключиться к системе</button>
                </div>
            </div>
        </div>

    );

}

const mapStateToProps = ({ user: { data, loading, error, validation }, language: { texts }}) => {
    return { data, loading, error, texts, validation };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: fetchUser(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

/*

class LoginPage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            userLabel: "Введите имя пользователя",
            userValue: localStorage.getItem('user') || "",
            passwordLabel: "Введите пароль",
            passwordValue: localStorage.getItem('pass') || "",
            saveLoginPass: localStorage.getItem('rememberMe') === "true" || false
        }
    }

    loginHandler = (e) => {
        e.preventDefault();

        const { saveLoginPass, userValue, passwordValue } = this.state;
        localStorage.setItem('rememberMe', saveLoginPass);
        localStorage.setItem('user', saveLoginPass ? userValue : '');
        localStorage.setItem('pass', saveLoginPass ? passwordValue : '');

        this.props.fetchUser({user: this.state.userValue, password: this.state.passwordValue});
    }

    onUserChange = (e) => {
        e.preventDefault();
        this.setState({userValue: e.target.value})
    }

    onPasswordChange = (e) => {
        this.setState({passwordValue: e.target.value})
    }

    onChangeHandler = (e, checked = null) => {
        if (e) e.preventDefault();

        this.setState({saveLoginPass: checked})
    }


    render () {

        const { data, loading, error } = this.props;

        if (loading) {
            return <span>loading...</span>
        }

        if (error) {
            return <p>{error}</p>
        }


        if (data.mainLabel) {
            return <Redirect to='/admin' />
        }

        const user = this.state.userValue;
        const password = this.state.passwordValue;

        const btnEnabled = (
            user !== "" &&
            user.length > MIN_LETTERS &&
            password !== "" &&
            password.length > MIN_LETTERS )

        const styleItem = {
            opacity: btnEnabled ? 1 : 0.3,
            pointerEvents: btnEnabled ? "auto" : "none"
        }

        return (

                <div className="login-area">
                    <form>
                        <div className="form-group">
                            <label htmlFor="loginInput">{this.state.userLabel}</label>
                            <input type="text" className="w-300" onChange={this.onUserChange} id="loginInput" value={this.state.userValue}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="loginPassword">{this.state.passwordLabel}</label>
                            <input type="password" className="w-300" id="loginPassword" onChange={this.onPasswordChange} value={this.state.passwordValue}/>
                            <small id="emailHelp">Вы можете получить имя пользователя в РУП Белтелеком</small>
                        </div>
                        <div>
                            <div className="d-flex-ac">
                                <CheckBox checked={this.state.saveLoginPass} callBackChange={(val, e) => this.onChangeHandler(e, val)} />
                                <p>Сохранить</p>
                            </div>
                        </div>
                        <div className="form-group mt-20">
                            <div style={styleItem}><button className="button" onClick={this.loginHandler}>Вход в систему</button></div>
                        </div>
                    </form>
                </div>

        );
    }
}

const mapStateToProps = ({ user: { data, loading, error }}) => {
    return { data, loading, error };
};

const apiService = new APIService();
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: fetchUser(apiService, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

*/
