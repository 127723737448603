import React, {Component} from "react";
import {dataItemUpdate, updateSection} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";

class Button extends Component {

    onClickHandler = (e) => {
        e.preventDefault();

        this.props.updateSection(this.props.redirectTo);
    }

    onChangeHandler = (e) => {
        e.preventDefault();

        this.props.dataItemUpdate({
            id: this.props.id,
            value: e.target.value,
            action: "update"
        })

    }

    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);

        return (
            <div className="form-group">
                <label>{dataItem.label}</label>
                <input type="text" onChange={this.onChangeHandler} value={dataItem.value}/>
                <button className="button" onClick={this.onClickHandler} >{dataItem.value}</button>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSection: updateSection(dispatch),
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Button);
