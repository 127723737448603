import React from 'react';

import image from "../../../assets/landing/mycity-footer.png"


const Footer = () => {

    return (
        <div >
            <div className="mt-50 h-50" />
            <div className="p-30">
                <p>РУП «Белтелеком». УНП 101007741</p>
                <p className="mt-10"><a href="https://www.beltelecom.by/_mycity/index.html" target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a></p>
            </div>
            {/*<div className="mt-50 pb-30">
               <img src={image} width="100%" alt="footer" style={{position: 'absolute', left: 0, width: '30%', minWidth: 290}}/>
            </div>*/}

        </div>

    );

}


export default Footer
