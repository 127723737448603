import React from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import DateTimeItem from "./date-time/form-datetime-item";
import CustomCheck from "../common/form-customcheck";
import DateItem from "./date-time/form-date-item";
import ApplyItem from "./date-time/form-apply-item";
import { v4 as uuidv4 } from 'uuid';
import SelectButton from "../common/select-button";
import {newElementsFactory, newElementsService} from "../../../services/data-service/data-service";
import PaymentItem from "./payment/form-payment-item";
import DeliveryItem from "./delivery/form-delivery-item";
import DateRecurrentItem from "./date-time/form-daterecurrent-item";
import {sortDates} from "../../../utils/utils";


const FormServiceContainer = props => {

    const {data, section, id, dataItemUpdate} = props;

    const renderDateTimeItem = (type, itemId) => {

        switch(type) {

            case 'date_time': return <DateTimeItem key={itemId} id={itemId} />
            case 'date': return <DateItem key={itemId} id={itemId} />
            case 'apply': return <ApplyItem key={itemId} id={itemId} />
            case 'paycard': return <PaymentItem key={itemId} id={itemId} />
            case 'payerip': return <PaymentItem key={itemId} id={itemId} />
            case 'delivery': return <DeliveryItem key={itemId} id={itemId} />

            case 'date_recurrent': return <DateRecurrentItem key={itemId} id={itemId} />

            default: return null;
        }
    }


    const addItemHandler = (index, item) => {

        const isRecurrent =  item.childType === undefined;

        const dataItem =  getItemById(data[section], id);
        const children = dataItem.children ? [...dataItem.children] : [];
        const recurrent = dataItem.recurrent_dates ? [...dataItem.recurrent_dates] : [];

        const obj = {...newElementsService(dataItem.serviceType, index)};
        obj.id = uuidv4();

        if (isRecurrent) {
            recurrent.push(obj)
        } else {
            children.push(obj);
        }

        const objToUpdate = isRecurrent ? "recurrent" : "children";
        const dataToUpdate = isRecurrent ? recurrent : children;

        const objToSend = {
            id: id,
            action: "update",
            [objToUpdate]: dataToUpdate
        }

        dataItemUpdate(objToSend)
    }

    const dataItem =  getItemById(data[section], id);

    const items = dataItem.children;
    sortDates(items)

    const recurrent = dataItem.recurrent_dates;
    const isRecurrent = (recurrent && recurrent.length > 0)

    const factory = newElementsFactory(dataItem.serviceType);

    const isVisible = dataItem.value === "on";

    return (
        <div className="service-container">

            <CustomCheck id={id} label={dataItem.label} />

            {isVisible &&

            <div>

                <div className="mt-20 mb-15">
                    <SelectButton disabled={isRecurrent} elements={factory} callBackSelectClick={addItemHandler} />
                    {dataItem.serviceType === "service_apply" &&
                    <p className="mt-15 fcgray fs-12">
                        Запись на дни недели автоматически создает записи типа "запись на время". Для корректной работы этого режима для услуги, следует предварительно удалить из услуги записи типа "запись в группу" или "запись на дату". Если вы уже активировали запись на дни недели, не удалив старых дат, удалите текущие выбранные дни и после этого вы сможете отредактировать старые даты.
                    </p>
                    }
                </div>

                {(items && !isRecurrent) &&
                items.map((item) => renderDateTimeItem(item.childType, item.id))
                }
                {isRecurrent &&
                recurrent.map((item) => {
                    item.id = item.id ? item.id : uuidv4()
                    return renderDateTimeItem("date_recurrent", item.id)
                })
                }
            </div>
            }
        </div>
    )
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormServiceContainer);
