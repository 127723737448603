import React, {Component} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import ServicePreviewItem from "./service-preview-item";


class ServicePreview extends Component {

    constructor() {
        super();

        this.state = {currentId:""}
    }

    handleClick = (e, id) => {
        e.preventDefault();

        this.setState({currentId: id})

    }

    renderServiceItem = (item) => {

        if (item.visible === "off") return null;

        switch(item.control) {

            case 'category': {
                return (
                    <div key={item.id}>
                        <button onClick={(e) => this.handleClick(e, item.id)} className="button-alt">{item.value}</button>
                    </div>
                )
            }
            case 'service': {
                return (
                    <ServicePreviewItem key={item.id} id={item.id} />
                )
            }


            default: return null;
        }
    }


    render () {

        const data = (this.state.currentId === "") ?
            this.props.data[this.props.section] :
            getItemById(this.props.data[this.props.section], this.state.currentId).children

            const isBack = this.state.currentId !== "";

        return (
            <div className="service-preview">
                <div>
                {
                    isBack &&
                    <button onClick={(e) => this.handleClick(e, "")} className="button-alt"> Назад</button>

                }
                </div>
                {data &&

                data.map(
                    (item) => {

                        return (
                            this.renderServiceItem(item)
                        )
                    }
                )
                }

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreview);