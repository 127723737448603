import React, {Component} from 'react';
import {getTextDate} from "../../../utils/dates";

class TextBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isImageErrored: false,
        }
    }

    onImageError = () => {
        this.setState({
            isImageErrored: true
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                isImageErrored: false
            })
        }
    }

    jsxFactory = (heading, text, type) => {
        switch (type) {
            case "address": {
                return (
                    <div>
                        <h4>{heading}</h4>
                        <p>{text.textValue}</p>
                    </div>
                );
            }
            case "worktime": {
                return (
                    <div>
                        <h4>{heading}</h4>
                        <p>{text}</p>
                    </div>
                );
            }
            case "contacts": {
                return (
                    <div>
                        <h4>{heading}</h4>
                        <p>{text}</p>
                    </div>
                );
            }
            case "email": {
                return (
                    <div>
                        <h4>{heading}</h4>
                        <p><a href={"mailto:"+text}>{text}</a></p>
                    </div>
                )
            }
            case "website": {
                return (
                    <div>
                        <h4>{heading}</h4>
                        <p><a href={text}>{text}</a></p>
                    </div>
                )
            }
            case "social": {
                return (
                    <div>
                        <p><a href={text}>{text}</a></p>
                    </div>
                )
            }
            case "headingtext": {
                return (
                    <div>
                        <h4>{text.headingValue}</h4>
                        <p>{text.textValue}</p>
                    </div>
                )
            }
            case "filial": {
                return (
                    <div>
                        <h4>{text.headingValue}</h4>
                        <p>{text.textValue}</p>
                    </div>
                )
            }
            case "text": {
                return (
                    <div>
                        <p>{text}</p>
                    </div>
                );

            }
            case "heading": {
                return (
                    <div>
                        <h4>{text}</h4>
                    </div>
                );

            }
            case "image": {
                return (
                    <div>
                        { !this.state.isImageErrored &&
                        <img width="100%" src={text} onError={this.onImageError} alt="img block"/>
                        }
                    </div>
                )
            }
            case "servicesbutton": {
                return (
                    <div>
                        <button className="button">{text}</button>
                    </div>
                )
            }
            case "newsbutton": {
                return (
                    <div>
                        <button className="button">{text}</button>
                    </div>
                )
            }

            case "newsitem": {
                return (
                    <div className="d-flex">
                        {text.imageValue && !this.state.isImageErrored &&
                        <div className='mr-15'>
                            <img className='image-preview' src={text.imageValue} onError={this.onImageError} alt='news' />
                        </div>
                        }
                        <div>
                            <p className='fs-14'><strong>{text.headingValue}</strong></p>
                            {text.date && <p className='mt-5 fcgray'><span>{getTextDate(text.date) + (text.time ? " | " + text.time : '')}</span></p>}
                        </div>
                    </div>
                )
            }
            default: return null;
        }
    }

    render () {

        const jsxControl = this.jsxFactory(this.props.label, this.props.value, this.props.control)

        return (
            <div className="text-block">
                {jsxControl}
            </div>
        );
    }
}

export default TextBlock;
