import React, { useEffect } from "react";
import {connect} from "react-redux";
import {fetchRequests, updateRequest} from "../../../actions";
import RequestItem from "./request-item";



const REFRESH_INTERVAL = 30 * 1000;

const Requests = props => {

    const {fetchRequests,  requests} = props


    useEffect(() => {
        fetchRequests();
        let interval = setInterval(() => {
            fetchRequests();
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h4>Обращения</h4>
            <div className='mt-20'>
                {requests.map((item, index) => <RequestItem key={index} item={item} />)}

            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, requests }}) => {
    return { data, section, requests };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRequests: fetchRequests(dispatch),
        updateRequest: updateRequest(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
