import React, {useState} from 'react';
import { connect } from 'react-redux';
import { fetchModerator } from '../../actions';
import CheckBox from "../form-controls/common/form-checkbox";
import {Redirect} from "react-router";
import {InputLabeled} from "../form-controls";

import { isFormValid } from "../../utils/validators";

const LoginPageModerator = props => {

    const [login, setLogin] = useState(localStorage.getItem('user-moderator') || '')
    const [password, setPassword] = useState(localStorage.getItem('pass-moderator') || '')
    const [saveLoginPass, setSaveLoginPass] = useState(localStorage.getItem('rememberMe-moderator') === 'true' || false)

    const { loginScreen: { loginText, loginNote, passText, saveMeText } } = props.texts.screens;
    const { data, validation, fetchModerator } = props;

    const isValidated = isFormValid(validation['login']);

    // Submit username and password and store them in localStorage if appropriate checkbox set
    const submitHandler = () => {
        if (!isValidated) return;

        localStorage.setItem('rememberMe-moderator', saveLoginPass.toString());
        localStorage.setItem('user-moderator', saveLoginPass ? login : '');
        localStorage.setItem('pass-moderator', saveLoginPass ? password : '');

        fetchModerator(login, password);
    }

    if (data.mainLabel) {
        return <Redirect to='/admin' />
    }

    return (

        <div className="login-area">
            <div className="form-group">
                <InputLabeled
                    submit={submitHandler}
                    mandatory
                    form='login'
                    label={loginText}
                    initValue={login}
                    onChange={setLogin} />
            </div>
            <div className="form-group">
                <InputLabeled
                    submit={submitHandler}
                    mandatory
                    form='login'
                    type='password'
                    label={passText}
                    initValue={password}
                    onChange={setPassword} />
                <small>{loginNote}</small>
            </div>
            <div>
                <div className="d-flex-ac">
                    <CheckBox checked={saveLoginPass} callBackChange={(val) => setSaveLoginPass(val)} />
                    <p className='fs-14-12'>{saveMeText}</p>
                </div>
            </div>
            <div className='separator1px mt-20' />
            <div className="mt-20">
                <div className='d-flex-wrap-sb-ac'>
                    <button className={`button ${isValidated ? 'enabledClick' : 'disabledClick'}`} onClick={submitHandler}>Войти</button>
                    <button className="button-link fs-12" onClick={console.log}>Подключиться к системе</button>
                </div>
            </div>
        </div>

    );

}

const mapStateToProps = ({ user: { data, loading, error, validation }, language: { texts }}) => {
    return { data, loading, error, texts, validation };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModerator: fetchModerator(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageModerator);
