import React, {useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import {ImageLabeled, InputLabeled} from "../index";

const FormBranch = props => {

    const {data, section, id, dataItemUpdate, cities} = props;

    const [expanded, setExpanded] = useState(false);

    const onChangeHandler = (type, value, isAdditionalData) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                ...(!isAdditionalData && {[type]: value}),
                ...(isAdditionalData && {
                    additionalData: {
                        ...(dataItem.value?.additionalData || {}),
                        [type]: value
                    }
                }),

            },
            action: "update"
        })
    }

    const dataItem =  getItemById(data[section], id);
    const { headingValue, textValue, additionalData={} } = dataItem.value;
    const {
        lat,
        lng,
        city=data.city,
        address,
        worktime,
        contacts,
        email,
        website,
        image,
    } = additionalData

    return (
        <div style={{marginBottom: 30}}>
            {!expanded && <button className={'button-link'} onClick={() => setExpanded(true)}>редактировать</button>}
            {expanded && <button className={'button-link'} onClick={() => setExpanded(false)}>свернуть</button>}
            {expanded && <div style={{padding: 20, border: '1px #ccc solid'}}>
                <h3>Отделение</h3>
                <div className="form-group" style={{marginTop: 20}}>
                    <InputLabeled label={'название отделения'} mandatory initValue={headingValue}
                                  onChange={(value) => onChangeHandler('headingValue', value)}/>
                </div>
                <div className="form-group">
                    <InputLabeled textarea label={'описание отделения'} mandatory initValue={textValue}
                                  onChange={(value) => onChangeHandler('textValue', value)}/>
                </div>
                <div className="form-group">
                    <InputLabeled textarea label={'контакты'} mandatory initValue={contacts}
                                  onChange={(value) => onChangeHandler('contacts', value, true)}/>
                </div>
                <div className="form-group" style={{width: 250}}>
                    <select value={city} onChange={e=>onChangeHandler('city', e.target.value, true)}>
                        {[{alias: 'all', name: '* Все города'}, ...(cities || [])].map((item, index) => <option key={index} value={item.alias}>{item.name}</option>)}
                    </select>

                </div>
                <div className="form-group">
                    <InputLabeled label={'адрес'} mandatory initValue={address}
                                  onChange={(value) => onChangeHandler('address', value, true)}/>
                </div>
                <div className="form-group">
                    <div className='wp-100 mt-20 d-flex' style={{gap: 20}}>
                        <div>
                            <div className='fs-10 mb-5'>Lat</div>
                            <InputLabeled mandatory type={'number'} onChange={(val) => onChangeHandler('lat', val, true)} initValue={lat || ''} />
                        </div>
                        <div>
                            <div className='fs-10 mb-5'>Lng</div>
                            <InputLabeled mandatory type={'number'} onChange={(val) => onChangeHandler('lng', val, true)} initValue={lng || ''} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <InputLabeled textarea label={'время работы'} mandatory initValue={worktime}
                                  onChange={(value) => onChangeHandler('worktime', value, true)}/>
                </div>
                <div className="form-group">
                    <ImageLabeled heading={'Изображение'} initValue={image}
                                  onChange={(value) => onChangeHandler('image', value, true)} local/>
                </div>
                <div className="form-group">
                    <InputLabeled label={'вебсайт'} initValue={website}
                                  onChange={(value) => onChangeHandler('website', value, true)}/>
                </div>
                <div className="form-group">
                    <InputLabeled label={'email'} initValue={email}
                                  onChange={(value) => onChangeHandler('email', value, true)}/>
                </div>
            </div>}
            {!expanded && <div style={{padding: 20, border: '1px #ccc solid'}}>
                <div className={'d-flex'} style={{gap: 20}}>
                    {image && <img src={image} style={{width: 100, height: 60, objectFit: 'cover'}} />}
                    <div>
                        {headingValue && <strong className={'fs-14'}>{headingValue}</strong>}
                        {textValue && <p className={'fs-12 mt-5'}>{textValue?.substring(0, 100)}</p>}
                        {address && <p className={'fs-12 mt-5'}>{address}</p>}
                    </div>
                </div>

            </div>}
        </div>
    )
}

const mapStateToProps = ({ user: { data, section, cities }, language: {texts}}) => {
    return { data, section, texts, cities };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBranch);
