import React, {Component} from "react";

export default class SelectLabeled extends Component {

    constructor(props) {

        super(props);

        this.state = {
            elements: props.elements,
            label: props.label,
            value: props.value,
            index: props.index,
            callBackUpdate: props.callBackUpdate
        }
    }

    onChangeHandler = (e) => {
        e.preventDefault();
        //this.state.callBackUpdate(this.state.index, e.target.value);
        this.setState({value: e.target.value})
    }

    render () {

        const {elements} = this.state;

        return (
            <div>
                <select>
                    {elements.map(
                        (item, index) => <option key={index}>{item}</option>
                    )}
                </select>
            </div>
        );
    }
}
