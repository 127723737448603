import React, {useEffect, useState, useCallback} from 'react';

const INFO_VERSION = '0.02'

const InfoPanel = props => {

    const { initVisible, callback } = props

    const [visible, setVisible] = useState(initVisible);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        console.log('--', initVisible)
        setVisible(initVisible)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initVisible]);

    const hidePanel = () => {
        localStorage.setItem('hide-info-panel', INFO_VERSION);
        setVisible(false)
        callback && callback();
        forceUpdate();
    }


    const lsVisible = localStorage.getItem('hide-info-panel') !== INFO_VERSION
    const isVisible = visible || lsVisible

    if (!isVisible) return null;

    return (

        <div className="infoPanel">

            <p className='td-strong'>Уважаемые пользователи платформы «Мой Город»,</p>
            <p className='mt-15 fs-14'>на платформе появилось обновление функциональности:</p>
            <ul className='mt-10'>
                <li className='fs-14'>В личном кабинете появилась возможность увидеть статистику открытий и записей на сервисы по своей организации. </li>
                <li className='fs-14'>В личном кабинете появилась возможность указать дополнительную категорию для вашей органиазации, чтобы в мобильном приложении вашу организацию могли найти сразу в двух категориях.</li>
                <li className='fs-14'>Для активных пользователей мессенджера Telegram теперь есть возможность получать уведомления о записях на сервисы через чат-бот <a href='https://t.me/moygorodby_bot' target='_blank' rel='noopener noreferrer'>@moygorodby_bot</a>. Введите свои имя пользователя и пароль и будьте в курсе всех записей в режиме реального времени!</li>

            </ul>
            <p className='mt-30 fs-14'>Предложения по улучшению или сообщения и проблемах после обновления можно присылать на наш <a href='mailto:moygoroddev@gmail.com' target='_blank' rel='noopener noreferrer'>email разработчиков</a></p>
            <div className='mt-30'>
                <button className='button' onClick={hidePanel}>Закрыть</button>
            </div>
        </div>

    );

}

export default InfoPanel;
