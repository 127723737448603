import React, {Component} from "react";
import {getItemById} from "../../../../utils";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";


class ServicePreviewDelivery extends Component {

    render () {

        const data = getItemById(this.props.data[this.props.section], this.props.id)
        const value = data.value

        return (
            <div className="service-preview-item">

                <h3>{value.headingValue}</h3>
                <p>Тип доставки: {value.textValue}</p>
                <p>Цена: <strong>{value.priceValue}р</strong></p>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreviewDelivery);