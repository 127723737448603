import React, {useState} from "react";
import InputLabeled from "../../form-controls/common/form-input-labeled";


const OperationsApplyEmptyItem = props => {

    const { callBackOrder } = props

    const [personInput, setPersonInput] = useState('')
    const [phoneInput, setPhoneInput] = useState('')
    const [infoInput, setInfoInput] = useState('')
    const [statusInput, setStatusInput] = useState('')
    //const [validationError, setValidationError] = useState(false)

    const onAddHandler = () => {

        if (personInput.length < 3) {
            //setValidationError(true)
            return;
        }

        callBackOrder({
            person: personInput,
            phone: phoneInput,
            info: infoInput,
            status: statusInput,
        })

        setPersonInput('')
        setInfoInput('')
        setPhoneInput('')
        setStatusInput('')
    }


    return (
        <div className="d-flex-wrap" style={{alignItems: 'center'}}>
            <div className="mr-20">
                <InputLabeled mandatory className='w-150 inputMobile' label='Имя' initValue={personInput} onChange={setPersonInput} />
            </div>
            <div className="mr-20">
                <InputLabeled className='w-150 inputMobile' label='Телефон' initValue={phoneInput} onChange={setPhoneInput} />
            </div>
            <div className="mr-20">
                <InputLabeled className='w-150 inputMobile' label='Инфо' initValue={statusInput} onChange={setStatusInput} />
            </div>

            <div className="w-300 mr-5">
                <button className="button-alt" onClick={onAddHandler}>Добавить</button>
            </div>

        </div>
    )

}


export default OperationsApplyEmptyItem;
