import React, {Component} from "react";
import TextBlock from "../text-block";
import {connect} from "react-redux";
import ServicePreview from "../service-preview/service-preview";

class MPContainer extends Component {

    mpComponentFactory = (sectionName, item, index) => {

        if (item.visible === "off") return null;

        switch (sectionName) {
            case "services": {
                return (
                    <ServicePreview
                        key={item.id}
                        id={item.id}
                    />
                )
            }
            default : {
                return (
                    <TextBlock
                        key={item.id}
                        label={item.label}
                        value={item.value}
                        control={item.control}>

                    </TextBlock>
                )
            }
        }
    }

    render () {

        const section = this.props.section;
        const data = this.props.data[section];

        const isServices = section === "services";

        return (
            <div>
                {!isServices &&
                    data.map(
                        (item, index) => {
                            return this.mpComponentFactory(section, item, index)
                        }
                    )
                }
                {isServices &&
                    <ServicePreview />
                }
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};


export default connect(mapStateToProps)(MPContainer);