import React, {useEffect, useState} from 'react';
import InputLabeled from "../common/form-input-labeled";


const FormNewsPoll = props => {

    const { pollData, callback } = props

    const [question, setQuestion] = useState(pollData ? pollData.question || '' : '')
    const [options, setOptions] = useState(pollData ? pollData.options || ['',''] : ['',''])

    useEffect(() => {
        setOptions(pollData ? pollData.options || ['',''] : ['','']);
        setQuestion(pollData ? pollData.question || '' : '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollData]);

    const updateQuestion = (value) => {
        update(value, options)
    }

    const updateAnswer = (index, value) => {
        let copy = [...options];
        copy[index] = value;
        update(question, copy)
    }

    const addHandler = () => {
        if (options.length >= 6) return;
        update(question, [...options, ''])
    }
    const deleteHandler = () => {
        if (options.length === 2) return;
        let copy = [...options];
        copy.pop();
        update(question, copy)
    }

    const update = (questionToChange=question, optionsToChange=options) => {
        callback({question: questionToChange, options: optionsToChange})
    }

    return (
        <div>
            <InputLabeled label='Вопрос для голосования' className="w-280" type='text'
                          onChange={(value) => updateQuestion(value)}
                          initValue={question || ''} />

            <div className='mt-20'>
                {options.map((item, index) => <div key={index}><InputLabeled label={'Вариант ответа ' + (index+1)} className="wp-100" type='text'
                                                            onChange={(value) => updateAnswer(index, value)}
                                                            initValue={options[index] || ''} /></div>)}
            </div>
            <div className='d-flex-ac'>
                <button onClick={addHandler} className='button-link'>Добавить</button>
                <button onClick={deleteHandler} className='button-link ml-20'>Убрать</button>
            </div>

            <div className='separator1px mt-10 mb-10' />
        </div>
    )

}

export default FormNewsPoll;
