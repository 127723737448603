import React from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"


const FormGoogleMap = withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultOptions={{disableDefaultUI: true, draggable:false}}
        center={{lat: props.geo.lat, lng: props.geo.lng}}
    >
        <Marker position={{lat: props.geo.lat, lng: props.geo.lng}} />
    </GoogleMap>)

const FormMap = (props) => {


    const { address } = props;
    const localAddress = address && address.lat && address.lng ? address : {
        address: "",
        lat: -34.397,
        lng: 150.644
    }

    return (
        <FormGoogleMap geo={localAddress}
                       loadingElement={<div style={{height: `100%`}}/>}
                       containerElement={<div style={{width: '290px', height: '200px'}}/>}
                       mapElement={<div style={{height: `100%`}}/>}/>
    )


}


export default FormMap
