import React from 'react';


const FeedbackForm = () => {

    return (

        <div>
            <iframe frameBorder={0} className="airtable-embed airtable-dynamic-height" title='feedback' src="https://airtable.com/embed/shrZGl5jQjKCwk6XN"
                    width="100%" height="935" style={{background: 'transparent', border: '1 solid #ccc'}}/>
        </div>

    );

}

export default FeedbackForm
