import {v4 as uuidv4} from "uuid";
import {getBrowserDateToday} from "../../utils";

const NEW_ELEMENT_DATETIME = () => {
    return {
        id: uuidv4(),
        label: "Запись на время",
        childType: "date_time",
        value: {
            date: "",
            time: [],
        }
    }
}

const NEW_ELEMENT_RECURRENT = () => {
    return {
        label: "Запись по дням недели",
        value: {
            day: "понедельник",
            time: [],
        }
    }
}

const NEW_ELEMENT_DATE = () => {
    return {
        id: uuidv4(),
        label: "Запись на дату",
        childType: "date",
        value: {
            date: "",
            limit: 1,
        }
    }
}

const NEW_ELEMENT_APPLY = () => {
    return {
        id: uuidv4(),
        label: "Запись в группу",
        childType: "apply",
        value: {
            applyLabel: "Заголовок для записи",
            applyValue: "Запись в группу",
            limit: 10,
        }
    }
}

const NEW_ELEMENT_DELIVERY = () => {
    return {
        id: uuidv4(),
        label: "Новая онлайн доставка",
        childType: "delivery",
        value: {
            headingLabel: "Название вида доставки",
            headingValue: "",
            textLabel: "Описание доставки",
            textValue: "",
            priceLabel: "Цена доставки в бел рублях",
            priceValue: "",
        }
    }
}

const NEW_ELEMENT_PAYCARD = () => {
    return {
        id: uuidv4(),
        label: "Оплата банковской картой",
        childType: "paycard",
        value: {
            headingLabel: "Название оплаты",
            headingValue: "Оплата с использованием банковской карты",
            textLabel: "Описание оплаты",
            textValue: "Подходит для владельцев бансковских карт Visa и Mastercard",
            priceLabel: "Цена в белорусских рублях",
            priceValue: "",
        }
    }
}

const NEW_ELEMENT_PAYERIP = () => {
    return {
        id: uuidv4(),
        label: "Оплата через ЕРИП",
        childType: "payerip",
        value: {
            headingLabel: "Название оплаты",
            headingValue: "Оплата с использованием ЕРИП",
            textLabel: "Описание оплаты",
            textValue: "Вы можете оплатить сами по информации о платеже в ЕРИП",
            priceLabel: "Цена в белорусских рублях",
            priceValue: "",
        }
    }
}

const NEW_ELEMENT_CATEGORY = () => {
    return {
        id: uuidv4(),
        label: "Категория",
        visible: "on",
        value: "",
        control: "category",
        children: []
    }
}

const NEW_ELEMENT_SERVICE = () => {
    return {
        label: "Сервис",
        visible: "on",
        value: {
            headingLabel: "Название сервиса",
            headingValue: "",
            textLabel: "Описание сервиса",
            textValue: "",
            imageLabel: "Изображение сервиса",
            imageValue: "",
            priceLabel: "Цена услуги",
            priceValue: "",
            linkLabel: "Ссылка на сайт",
            linkValue: ""
        },
        control: "service",
        children: [
            {
                id: uuidv4(),
                label: "Онлайн-запись",
                serviceType: "service_apply",
                value: "off",
                children: []
            },
            {
                id: uuidv4(),
                label: "Онлайн-доставка",
                serviceType: "service_delivery",
                value: "off",
                children: []
            },
            {
                id: uuidv4(),
                label: "Онлайн-оплата",
                serviceType: "service_payment",
                value: "off",
                children: []
            },
        ]
    }
}

const NEW_ELEMENT_NEWSITEM = () => {
    return {
        id: uuidv4(),
        control: "newsitem",
        label: "Новость",
        visible: "on",
        value: {
            date: getBrowserDateToday(),
            time: "",
            headingLabel: "Заголовок новости",
            headingValue: "",
            textLabel: "Текст новости",
            textValue: "",
            imageLabel: "Изображение к новости",
            imageValue: "",
            linkLabel: "Ссылка на полную новость (необязательно)",
            linkValue: "",
        }
    }
}

const INFO_CONTROLS = [
    {label: "Адрес", control: "address", isHT: false},
    {label: "Время работы", control: "worktime", isHT: false},
    {label: "Контакты", control: "contacts", isHT: false},
    {label: "E-mail", control: "email", isHT: false},
    {label: "Вебсайт", control: "website", isHT: false},
    {label: "Социальная сеть", control: "social", isHT: false},
    {label: "Изображение", control: "image", isHT: false},
    {label: "Текстовый блок", control: "text", isHT: false},
    {label: "Заголовок", control: "heading", isHT: false},
    {label: "Текстовый блок с заголовком", control: "headingtext", isHT: true},
    {label: "Отделение / Филиал", control: "filial", isHT: true},
]
/*
const INFO_BRANCHES = [
    {label: "Отделение / Филиал", control: "filial", isHT: true},
]
 */

const getHTObject = (control) => {
    if (control === "headingtext") return {headingLabel: "Заголовок текстового блока", textLabel: "Текстовый блок"}
    if (control === "filial") return {headingLabel: "Название отделения", textLabel: "Информация об отделении"}
    return null;
}

const NEW_ELEMENT_LABELEDVALUE = (item, isBranches, city, organizationId) => {

    let objHT = item.isHT ? getHTObject(item.control) : null

    return {
        control: item.control,
        label: item.label,
        visible: "on",
        value: item.isHT ?
            {
                headingLabel: objHT.headingLabel,
                headingValue: item.control === "filial" ? "Отделение XXX" : "",
                textLabel: objHT.textLabel,
                textValue: "",
                additionalData: item.control === "filial" && isBranches && city ? { city, organizationId } : {}
            }
            : ""
    }
}

const NEW_ELEMENTS_APPLY = [NEW_ELEMENT_DATETIME(), NEW_ELEMENT_DATE(), NEW_ELEMENT_APPLY(), NEW_ELEMENT_RECURRENT()];
const NEW_ELEMENTS_DELIVERY = [NEW_ELEMENT_DELIVERY()];
const NEW_ELEMENTS_PAYMENT = [NEW_ELEMENT_PAYCARD(), NEW_ELEMENT_PAYERIP()]

export function newElementsFactory(type, index=-1, isBranches=false, city="", organizationId) {
    switch(type) {

        case 'service_apply': return NEW_ELEMENTS_APPLY;
        case 'service_delivery': return NEW_ELEMENTS_DELIVERY;
        case 'service_payment': return NEW_ELEMENTS_PAYMENT;

        case 'category': return NEW_ELEMENT_CATEGORY();
        case 'service': return NEW_ELEMENT_SERVICE();
        case 'services': return [NEW_ELEMENT_CATEGORY()];

        case 'newsitem': return NEW_ELEMENT_NEWSITEM();
        case 'news': return [NEW_ELEMENT_NEWSITEM()];

        case 'infosection': return INFO_CONTROLS


        default: {
            let obj;
            if (index >= 0) {
                obj = INFO_CONTROLS[index];
            } else {
                obj = INFO_CONTROLS.find(x => x.control === type)
            }

            return obj ? NEW_ELEMENT_LABELEDVALUE(obj,isBranches, city, organizationId) : null;
        }
    }
}

export function newElementsService(type, index=-1) {
    switch(type) {

        case 'service_apply': {
            if (index === 0) return NEW_ELEMENT_DATETIME()
            if (index === 1) return NEW_ELEMENT_DATE()
            if (index === 2) return NEW_ELEMENT_APPLY()
            if (index === 3) return NEW_ELEMENT_RECURRENT()
            break;
        }
        case 'service_delivery': {
            return NEW_ELEMENT_DELIVERY()
        }
        case 'service_payment': {
            if (index === 0) return NEW_ELEMENT_PAYCARD()
            if (index === 1) return NEW_ELEMENT_PAYERIP()
            break;
        }

        default: return null;
    }
}
