import React, {useState} from 'react';
import FormItem from "./form-item";
import {connect} from "react-redux";
import {getChildrenById} from "../../utils";
import {dataItemUpdate} from "../../actions";
import ControlsGenerator from "./controls-generator/controls-generator";
import SaveButton from "../savebutton/save-button";

const newsFilter = [
    {pageIndex: 0, label: 'Все', filter: 'all'},
    {pageIndex: 1, label: 'Новости', filter: 'news'},
    {pageIndex: 2, label: 'Важные новости', filter: 'special'},
    {pageIndex: 3, label: 'События', filter: 'main'},
    {pageIndex: 4, label: 'Акции', filter: 'offers'},
    {pageIndex: 5, label: 'Меню', filter: 'menu'},
]

const infoFilter = [
    {pageIndex: 0, label: 'Информация', filter: 'all'},
    {pageIndex: 1, label: 'Отделения', filter: 'branches'},
]



const getNewsCondition = (newsItem, index) => {
    const filterItem = newsFilter.find(el => el.pageIndex === index )
    if (filterItem) {
        if (filterItem.filter === 'news') return (!newsItem?.value?.offers && !newsItem?.value?.menu)
        return !!newsItem?.value?.[filterItem.filter]
    }
}

const getInfoCondition = (item, index, city) => {

    return (index === 1)
        ? (item.control === 'filial' && (city === 'all' ? true : item.value?.additionalData?.city === city))
        : item.control !== 'filial'
}

const selectedMenuStyle = {
    textDecoration: 'underline',
    color: '#32B9F1'
}

const Form = props => {

    const { id, data, section, level, cities } = props

    const [newsPage, setNewsPage] = useState(0)
    const [infoPage, setInfoPage] = useState(0)
    const [city, setCity] = useState('all')

    const dataArray = id ? getChildrenById(data[section], id) : data[section];
    const levelValue = level || 0;

    let filteredArray = [...dataArray]

    if (section === 'news') {
        if (newsPage > 0) {
            filteredArray = dataArray.filter(el => getNewsCondition(el, newsPage))
        }
    }


    let branchesNumber = 0
    if (section === 'infosection' && data.branches) {
        filteredArray = dataArray.filter(el => getInfoCondition(el, infoPage, city))
        branchesNumber = dataArray.filter(el => getInfoCondition(el, 1, city)).length
    }

    const citiesBranches = Array.from(new Set(dataArray.map(el => el.value?.additionalData?.city)))
    const citiesList = cities.filter(el => citiesBranches.includes(el.alias))


    return (
        <div>
            {section === 'news' && data.type === 'power' && <div>
                <div className='mt-20 d-flex-wrap' style={{gap: 20}}>
                    {newsFilter.map((el, elIndex) => <p key={elIndex} onClick={() => setNewsPage(elIndex)} className='fs-14 enabledClick' style={newsPage === elIndex ? {} : selectedMenuStyle}>{el.label}</p>)}
                </div>
                <div className='separator1px mt-20' />
            </div>}
            {section === 'infosection' && data.branches && <div>
                <div className={'mt-20 d-flex-sb wp-100'}>
                    <div className='d-flex-wrap' style={{gap: 20}}>
                        {infoFilter.map((el, elIndex) => <p key={elIndex} onClick={() => setInfoPage(elIndex)} className='fs-14 enabledClick' style={infoPage === elIndex ? {} : selectedMenuStyle}>{`${el.label} ${elIndex === 1 ? '('+branchesNumber+')': ''}`}</p>)}
                    </div>
                    {infoPage === 1 && <div style={{width: 150}}>
                        <select value={city} onChange={e=>setCity(e.target.value)}>
                            {[{alias: 'all', name: '* Все города'}, ...(citiesList || [])].map((item, index) => <option key={index} value={item.alias}>{item.name}</option>)}
                        </select>

                    </div>}
                </div>
                <div className='separator1px mt-20' />
            </div>}
            {
                filteredArray.map(
                    (item, index) => {

                        return (
                            <FormItem
                                key={ item._id }
                                level={ levelValue }
                                id={ item._id }
                                index={ index }
                                total={ filteredArray.length - 1 }
                                data={ item }>

                            </FormItem>
                        )
                    }
                )
            }
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, cities }}) => {
    return { data, section, cities };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
