import React, {Component} from "react";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../../utils";

class DeliveryItem extends Component {

    onChangeHandler = (e) => {
        e.preventDefault();

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);

        const headingValue = (e.target.name === "heading") ? e.target.value : dataItem.value.headingValue
        const textValue = (e.target.name === "text") ? e.target.value : dataItem.value.textValue
        const priceValue = (e.target.name === "price") ? e.target.value : dataItem.value.priceValue

        const value = {
                headingLabel: dataItem.value.headingLabel,
                headingValue: headingValue,
                textLabel: dataItem.value.textLabel,
                textValue: textValue,
                priceLabel: dataItem.value.priceLabel,
                priceValue: priceValue,
        }

        this.updateReducer(value);
    }

    onDeleteHandler = (e) => {
        e.preventDefault();

        this.updateReducer(null, "delete");
    }

    updateReducer = (value, action="update") => {

        this.props.dataItemUpdate({
            id: this.props.id,
            value: value,
            action: action
        })
    }

    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);

        return (
            <div>
                <div className="service-heading">
                    <h4>{dataItem.label}</h4>
                    <p><button className="button" onClick={this.onDeleteHandler}>X</button></p>
                </div>
                <div className="form-group">
                    <label>{dataItem.value.headingLabel}</label>
                    <input name="heading" type="text" onChange={this.onChangeHandler} value={dataItem.value.headingValue}/>
                </div>
                <div className="form-group">
                    <label>{dataItem.value.priceLabel}</label>
                    <input className="control100px-right" name="price" type="number" onChange={this.onChangeHandler} value={dataItem.value.priceValue}/>

                </div>
                <div>
                    <p>{dataItem.value.textLabel}</p>
                    <textarea name="text" type="text" onChange={this.onChangeHandler} value={dataItem.value.textValue}/>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryItem);