import React, {Component} from "react";
import {getItemById} from "../../../../utils";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";


class ServicePreviewApply extends Component {

    render () {

        const data = getItemById(this.props.data[this.props.section], this.props.id)
        const value = data.value

        return (
            <div className="service-preview-item">

                <h3>{value.applyValue}</h3>
                <p>Осталось мест: {value.limit}</p>

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreviewApply);