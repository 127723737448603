import React, {Component} from "react";
import ico_ok from "../../../assets/ico_ok.svg";


class OperationsStatusControl extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked
        }

        this.checkedflag = props.checked;
    }

    onChangeHandler = (e) => {
        e.preventDefault();

        this.checkedflag = !this.checkedflag;

        this.setState({checked: this.checkedflag})

        this.props.callBackUpdate(this.checkedflag);

    }

    render () {

        const checkedStyle = this.state.checked ? "operations-check-bg-selected" : "";

        return (
            <div className={"operations-check-bg " + checkedStyle} onClick={this.onChangeHandler}>
                <img src={ico_ok} alt="ico_ok"/>
            </div>
        )
    }
}


export default OperationsStatusControl;