import React, {Component} from "react";
import {getItemById} from "../../../utils";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import OperationsDatetimeRow from "./operations-apply-row";
import {getTextDate} from "../../../utils/dates";


class OperationsGrid extends Component {

    generateEmptyOrders = (item) => {
        switch (item.childType) {
            case "date_time": return [];
            case "date": return [{limit: item.value.limit}];
            case "apply": return [{limit: item.value.limit}];
            default: return [];
        }
    }

    render () {

        const item = getItemById(this.props.data["services"], this.props.id);
        const itemsLabel = (item.childType === "date_time") ? "time" : "orders";

        item.value[itemsLabel] = item.value[itemsLabel] ? item.value[itemsLabel] : this.generateEmptyOrders(item);
        const items = item.value[itemsLabel];

        return (
            <div className="mt-30">

                <h3>{getTextDate(item.value.date)}</h3>

                <div className="mt-10">
                    {items &&

                    items.map(
                        (item, index) => {

                            return (
                                <OperationsDatetimeRow key={index} id={this.props.id} index={index} itemsLabel={itemsLabel} />
                            )
                        }
                    )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationsGrid);
