import React from "react";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../../utils";
import InputLabeled from "../../common/form-input-labeled";

const DateItem = props => {

    const {data, section, id, dataItemUpdate} = props;


    const dateChangeHandler = (label, val) => {

        const dataItem =  getItemById(data[section], id);
        const value = {...dataItem.value};

        value.date = (label === "date") ? val : dataItem.value.date
        value.limit = (label === "limit") ? val : dataItem.value.limit

        updateReducer(value);
    }

    const onDeleteHandler = () => {
        updateReducer(null, "delete");
    }

    const updateReducer = (value, action="update") => {
        dataItemUpdate({
            id: id,
            value: value,
            action: action
        })
    }

    const dataItem =  getItemById(data[section], id);

    return (
        <div>
            <div className="service-heading">
                <h4>{dataItem.label}</h4>
                <p><button className="button" onClick={onDeleteHandler}>X</button></p>
            </div>
            <div className="d-flex-wrap">
                <div className='mr-10'>
                    <InputLabeled label='Дата' className='w-160' type='date' initValue={dataItem.value.date} onChange={(val) => dateChangeHandler('date', val)} />
                </div>
                <div>
                    <InputLabeled label='Лимит' className='w-75' type='number' initValue={dataItem.value.limit} onChange={(val) => dateChangeHandler('limit', val)} />
                </div>

            </div>

        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateItem);
