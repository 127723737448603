import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { DataGrid, ruRU, useGridApiContext } from '@mui/x-data-grid';
import {FiX} from "react-icons/fi";
import {dataItemUpdate} from "../../../../actions";
import Multiselect from 'multiselect-react-dropdown';
import {v4 as uuidv4} from "uuid";
import {toTwoDigits} from "../../../../utils/dates";

const lessonTypes = [
    {name: 'Лекция'},
    {name: 'Семинар'},
    {name: 'Практика'},
]

const setIDs = (arr) => {
    return (arr || []).map((el, elIndex) => {
        return {
            ...el,
            id: `${el.lesson}-${elIndex}`
        }
    })
}

const startDate = '2024-09-02'
let weeks = 0
let weeksLabels = []
let prevDate = new Date(startDate)
for (let i = 0; i < 30; i++) {
    if (i > 0) {
        const nextDate = new Date(prevDate)
        nextDate.setDate(nextDate.getDate() + 6)
        if (nextDate.getFullYear() === 2024) {
            weeksLabels.push({
                label: `${toTwoDigits(prevDate.getDate())}.${toTwoDigits(prevDate.getMonth()+1)}-${toTwoDigits(nextDate.getDate())}.${toTwoDigits(nextDate.getMonth()+1)}`,
                date: prevDate,
                week: (i)
            })
            prevDate = nextDate
            weeks++
        }
    }
}
weeks = weeks - 1

const getRange = (hours) => {
    if (!hours) return 0
    let range = 2
    if (hours / 2 > weeks) range = 4
    return range
}

const createWeeksFromPlans = (plans) => {
    const weeklyPlan = []
    plans.forEach(plan => {
        const {lesson, lections, practice, seminars, course} = plan

        let lectionsNum = 0;
        let practiceNum = 0;
        let seminarsNum = 0;
        let lectionsRange = getRange(lections)
        let practiceRange = getRange(practice)
        let seminarsRange = getRange(seminars)

        weeksLabels.forEach(weekItem => {
            const {label} = weekItem
            const lectionsValue = (lectionsNum + lectionsRange) <= lections ? (lectionsRange) : 0
            const practiceValue = (practiceNum + practiceRange) <= practice ? (practiceRange) : 0
            const seminarsValue = (seminarsNum + seminarsRange) <= seminars ? (seminarsRange) : 0

            weeklyPlan.push({
                weekDay: label,
                lesson: lesson,
                lections: lectionsValue,
                practice: practiceValue,
                seminars: seminarsValue,
                course: course
            })

            lectionsNum +=lectionsRange
            practiceNum +=practiceRange
            seminarsNum +=seminarsRange
        })

    })
    return weeklyPlan
}

const GridPlans = ({ registry, updateRegistry, lessons, specializations, plans }) => {

    const planLabels = (lessons || []).map(el => el.name)

    const [rows, setRows] = useState(setIDs(registry) || [])
    const [filter, setFilter] = useState(planLabels?.[0] || '')

    function LessonsEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessons || []).map((item, index) => {return {name: item.value, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20'} style={{position: 'absolute', background: '#FFF' }}>
            <Multiselect placeholder={'Добавить'}
                options={secondaryOptions}
                selectedValues={secondaryOptionsSelected}
                onSelect={(selectedList) => handleChange(null, selectedList)}
                onRemove={(selectedList) => handleChange(null, selectedList)}
                displayValue="name" // Property name to display in the dropdown options
            />
            </div>
        );
    }

    function LessonsTypesEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessonTypes || []).map((item, index) => {return {name: item.name, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20'} style={{position: 'absolute', background: '#FFF' }}>
                <Multiselect placeholder={'Добавить'}
                             options={secondaryOptions}
                             selectedValues={secondaryOptionsSelected}
                             onSelect={(selectedList) => handleChange(null, selectedList)}
                             onRemove={(selectedList) => handleChange(null, selectedList)}
                             displayValue="name" // Property name to display in the dropdown options
                />
            </div>
        );
    }

    const renderLessonsEditInputCell = (params) => {
        return <LessonsEditInputCell {...params} />;
    };

    const renderLessonsTypesEditInputCell = (params) => {
        return <LessonsTypesEditInputCell {...params} />;
    };


    useEffect(() => {
        setRows(setIDs(registry) || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(registry)])

    const addRow = () => {
        const rowsCopy = [...rows]
        rowsCopy.push({
            id: uuidv4(),
            name: 'Новая запись',
            comment: '',
        })
        updateRegistry(rowsCopy)
    }

    const deleteRow = (row) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === row.id)
        if (existedRowIndex > -1) {
            rowsCopy.splice(existedRowIndex, 1)
        }
        updateRegistry(rowsCopy)

    }

    const processRowUpdate = (newRow) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === newRow.id)
        if (existedRowIndex > -1) {
            rowsCopy[existedRowIndex] = newRow
        }
        const updatedRow = { ...newRow, isNew: false };
        updateRegistry(rowsCopy);
        return updatedRow;
    };


    const columns = [
        { field: 'week', headerName: 'День недели', sortable: true, editable: false, width: 150,  },
        { field: 'lesson', headerName: 'Предмет', sortable: true, editable: false, width: 300, type: 'singleSelect', valueOptions: (lessons || []).map(el => el.value) },
        //{ field: 'specialization', headerName: 'Специализация', sortable: true, editable: true, width: 300, type: 'singleSelect', valueOptions: (specializations || []).map(el => el.name) },
        { field: 'lections', headerName: 'Лекции', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'practice', headerName: 'Практ', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'seminars', headerName: 'Семинары', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'course', headerName: 'Курс', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'delete', headerName: '', sortable: false, width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <FiX className='enabledClick' color='#000000' size={20} onClick={() => window.confirm('Вы уверены, что хотите удалить строку?') && deleteRow(params.row)} />
                    </div>
                );
            }
        },
    ];


    if (!Array.isArray(rows)) {
        return null
    }

    const filterList = ['Все'];
    rows.forEach(el => {
        const exists = filterList.find(listEl => listEl === el.lesson)
        if (!exists) filterList.push(el.lesson)
    })

    let filteredRows = rows
    if (filter !== 'Все') {
        filteredRows = rows.filter(el => el.lesson === filter)
    }

    return (
        <div>
            <h3 className={'mt-20 mb-20'}>График понедельного прохождения</h3>
            {/*<button className={'button-link mt-10 mb-10'} onClick={() => addRow()}>Добавить</button>*/}
            {/*<button className={'button-link mt-10 mb-10'} onClick={() => updateRegistry(createWeeksFromPlans(plans))}>Создать из плана</button>*/}
            <select className={'mb-10'} value={filter} onChange={e=>setFilter(e.target.value)}>
                {planLabels.map((item, index) => <option key={index} value={item}>{item}</option>)}
            </select>
            <div className='white' style={{ width: '100%', height: 600 }}>
                <div style={{ height: '100%', width: '100%', fontSize: 12 }}>
                    <DataGrid
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={filteredRows}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridPlans);
