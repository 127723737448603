export function exchangeArrayItems(arr=[], index1=0, index2=0) {

    if (arr.length === 0) return [];

    if (index1 < 0 || index1 > arr.length) return arr;
    if (index2 < 0 || index2 > arr.length) return arr;

    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];

}

export function exchangeArrayItemsNew(arr=[], index1=0, index2=0) {

    if (arr.length === 0) return [];

    if (index1 < 0 || index1 > arr.length) return arr;
    if (index2 < 0 || index2 > arr.length) return arr;

    const tmpArray = [...arr];
    [tmpArray[index1], tmpArray[index2]] = [tmpArray[index2], tmpArray[index1]];

    return tmpArray;
}

export function getChildrenById(arr=[], id) {

    let results = [];

    for (let i=0; i<arr.length; i++) {
        if (arr[i]._id === id) {
            if (arr[i].children) {
                return arr[i].children;
            } else {
                return [];
            }
        }

        if (arr[i].children) {
            results = getChildrenById(arr[i].children, id)
            if (results.length > 0)
            {
                return results;
            }
        }
    }

    return results
}

export function getItemById(arr=[], id) {

    let result = null;

    for (let i=0; i<arr.length; i++) {
        if (arr[i]._id === id || arr[i].id === id) {
            return arr[i];
        }

        if (arr[i].children) {
            result = getItemById(arr[i].children, id)
            if (result)
            {
                return result;
            }
        }

        if (arr[i].recurrent_dates) {
            result = getItemById(arr[i].recurrent_dates, id)
            if (result)
            {
                return result;
            }
        }
    }

    return result
}

export function getServiceItems(arr=[], outarr=[]) {

    let result = outarr;

    for (let i=0; i<arr.length; i++) {
        if (arr[i].control === "service") {
            result.push(arr[i]);
        }

        if (arr[i].children) {
            getServiceItems(arr[i].children, result)
        }
    }

    return result
}

export function getServiceItemsApply(arr=[], outarr=[]) {

    const services = getServiceItems(arr, [])
    const filteredServices = []
    services.forEach(item => {
        const serviceChildren = item.children || [];
        serviceChildren.forEach(child => {
            if (child.value !== 'off') {
                if (child.serviceType === 'service_apply' && child.children && child.children.length > 0) {
                    filteredServices.push(item);
                }
            }
        })
    })
    return filteredServices;
}

function convertMonth(date) {
    const month = Number(date.getMonth()) + 1;
    return (month < 10) ? `0${month}` : `${month}`;
}

export function getBrowserDateToday() {
    const date = new Date();

    return `${date.getFullYear()}-${convertMonth(date)}-${date.getDate()}`;
}

export function dateToBrowserDate(date) {
    return `${date.getFullYear()}-${convertMonth(date)}-${date.getDate()}`;
}

export function addressToValue(address, lat, lng) {
    return `${address}||${lat}||${lng}`;
}

export function sortTime (value) {

    if (!value.time || value.time.length < 2) return value

    value.time.sort((a, b) => {
        const aNum = Number(a.time.split(':').join(''));
        const bNum = Number(b.time.split(':').join(''));

        return aNum - bNum
    });

    return value
}

export function sortDates (arr) {

    if (arr.length < 2) return arr

    arr.sort((a, b) => {
        const aNum = Number(new Date(a.value.date));
        const bNum = Number(new Date(b.value.date));

        return aNum - bNum
    });

    return arr
}

export function valueToAddress(value) {

    const isString = typeof value;
    let res = {noCoords: false};

    if (isString) {
        res.address = value
        res.noCoords = true;

    } else {
        res = value
    }

    return res;
}

export function cleanHTML(htmlStr) {
    const regex = /(<([^>]+)>)/ig;
    let result = htmlStr
    result = result.split("&nbsp;").join(" ")
    result = result.split("<br>").join("\n")
    result = result.split("<br />").join("\n")
    result = result.replace(regex, '');

    return result
}
