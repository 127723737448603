import React  from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import {InputLabeled} from "../index";

const HeadingText = props => {

    const {data, section, id, texts, dataItemUpdate} = props;

    const {headingBlockComponent: {
        headingText,
        contentText,
    }} = texts.components

    const onChangeHandler = (type, value) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                [type]: value
            },
            action: "update"
        })
    }


    const dataItem =  getItemById(data[section], id);
    const { headingValue, textValue } = dataItem.value;


    return (
        <div>
            <div className="form-group">
                <InputLabeled label={headingText} mandatory initValue={headingValue}
                              onChange={(value) => onChangeHandler('headingValue', value)}/>
            </div>
            <div className="form-group">
                <InputLabeled textarea label={contentText} mandatory initValue={textValue}
                              onChange={(value) => onChangeHandler('textValue', value)}/>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user: { data, section }, language: {texts}}) => {
    return { data, section, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadingText);
