import React from "react";
import {getItemById} from "../../../utils";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import OperationsApplyRowItem from "./operations-apply-row-item";
import OperationsApplyEmptyItem from "./operations-apply-empty-item";


const OperationsDatetimeRow = props => {

    const {data, id, itemsLabel, dataItemUpdate} = props;

    const orderItemHandler = (item, action="update", index=0, status=false) => {
        const dataItem = getItemById(data["services"], id)
        const value = {...dataItem.value};

        const orderItem = value[itemsLabel][props.index];
        const order = (orderItem.order) ? orderItem.order : [];

        if (action === "update") {
            order.push(item);
        } else if (action === "delete") {
            order.splice(index, 1);
        } else if (action === "status") {
            order[index].status = status;
        }

        orderItem.order = order;

        dataItemUpdate({
            id: id,
            value: value,
            saveneeded: true,
            action: "update"
        })
    }

    const dataItem = getItemById(data["services"], id)
    const orderItem = dataItem.value[itemsLabel][props.index];

    const order = (orderItem.order) ? orderItem.order : [];
    const isAddComponent = order.length < orderItem.limit;

    return (
        <div className="mt-20">
            <div className="operations-timerow">
                {orderItem.time &&
                    <div className="w-75 mw-50 p-10 mr-5">
                        <p className='fs-10 mb-5'>Время</p>
                        <label>{orderItem.time}</label>
                    </div>
                }
                <div className="w-75 mw-50 p-10 mr-5">
                    <p className='fs-10 mb-5'>Лимит</p>
                    <label>{order.length+"/"+orderItem.limit}</label>
                </div>

                <div className='d-flex-wrap'>
                    <div>
                    {
                    order.map(
                        (item, index) => {

                            return (
                                <OperationsApplyRowItem callBackOrder={orderItemHandler} key={index} index={index} orderItem={item} />
                            )
                        }
                    )
                    }
                    </div>
                    <div>
                        {
                            isAddComponent &&
                            <OperationsApplyEmptyItem callBackOrder={orderItemHandler} />
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationsDatetimeRow);
