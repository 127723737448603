const months = ["ЯНВАРЯ", "ФЕВРАЛЯ", "МАРТА", "АПРЕЛЯ", "МАЯ", "ИЮНЯ", "ИЮЛЯ", "АВГУСТА", "СЕНТЯБРЯ", "ОКТЯБРЯ", "НОЯБРЯ", "ДЕКАБРЯ"]

export const toTwoDigits = (num) => `${num < 10 ? '0' : ''}${num.toString()}`

export const toBrowserDate = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
}

export const isToday = (dateToExtract) => {
    const date = new Date(dateToExtract)
    const today = new Date();
    return (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
}

export const browserDateToStringDate = (dateToExtract, isSmall= true) => {
    const date = new Date(dateToExtract);
    return getTextDate(date, false, isSmall)
}

// conversion to text representation

export const getTextDateTime = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    return `${getTextDate(date, isShort, isSmall)}, ${getTextTime(date)}`
}

export const getTextTime = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`
}

export const getTextDate = (dateToExtract, isShort=false, isSmall=true) => {
    const date = new Date(dateToExtract)
    if (isNaN(date)) return '';

    let monthLabel = isShort ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];
    if (isSmall) monthLabel = monthLabel.toLowerCase();
    return `${date.getDate()} ${monthLabel}`
}
