import React from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import SelectButton from "../common/select-button";
import {newElementsFactory} from "../../../services/data-service/data-service";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ControlsGenerator = props => {

    const {dataItemUpdate, section, data} = props
    const { width } = useWindowDimensions();

    const addItemHandler = (index, item) => {
        const obj = newElementsFactory(item.control, -1, !!data.branches, data.city, data._id);
        dataItemUpdate({
            value: obj,
            action: "add"
        })
    }


    const factory = newElementsFactory(section);

    return (
        <div>
            <SelectButton style={{width: width < 450 ? 120 : 300}} elements={factory} callBackSelectClick={addItemHandler} />
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlsGenerator);
