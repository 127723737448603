import React from 'react';

import desktop from "../../../assets/landing/mc-dektop.png"

const Organizations = () => {

    return (
        <div id="organizations" className="landing-white-box mt-50">
            <div className="landing-container">
                <div className="d-flex-sb wrapMobile">
                    <div className="wp-100 p-30">
                        <div>
                            <h1 className="mt-30">Для Организаций</h1>
                            <p className="mt-30">Каждая организация - участник проекта получает возможность более комфортного взаимодействия с
                            пользователями через цифровые инструменты</p>
                            <p className="mt-30">Приложение состоит из набора типовых модулей, доступных для заполнения
                                всем участникам. В частности, на платформе реализованы следующие
                                возможности:</p>
                             </div>
                        <div className="mt-30">
                            <ul className="mt-20">
                                <li>Сведения о компании</li>
                                <li>Новости компании</li>
                                <li>Афиша города</li>
                                <li>Акции в вашей компании</li>
                                <li>Модули онлайн-записи, онлайн-оплаты и онлайн-доставки</li>
                                <li>Кабинет оператора онлайн-услуг</li>
                            </ul>
                        </div>
                        <div className="mt-30">
                            <a href="https://forms.gle/TSjDctntsWoRb6Q7A" target="_blank" rel="noopener noreferrer"> <button className="button">ПОДКЛЮЧИТЬСЯ</button></a>
                        </div>
                        <div className="fs-10 fcgray mt-10">
                            Для организаций в городе
                        </div>
                    </div>
                    <div className="landing-gray-box-image wp-100">
                        <img src={desktop} style={{minWidth: 260, width: "90%"}} alt="phone" />
                    </div>
                </div>
            </div>
        </div>

    );

}


export default Organizations
