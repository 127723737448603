export const PASSWORD = 'password';
export const MANDATORY = 'mandatory';

export function getValidationOptions(type) {
    switch (type) {
        case PASSWORD: return {notEmpty: true, min: 8}
        case MANDATORY: return {notEmpty: true}
        default: return
    }
}
