import React, {Component} from "react";
import {dataItemUpdate} from "../../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../../utils";

class ApplyItem extends Component {


    onChangeHandler = (e) => {

        e.preventDefault();

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);
        const value = {...dataItem.value};

        value.applyLabel = (e.target.name === "heading") ? e.target.value : dataItem.value.applyLabel
        value.applyValue = (e.target.name === "text") ? e.target.value : dataItem.value.applyValue
        value.limit = (e.target.name === "limit") ? e.target.value : dataItem.value.limit

        this.updateReducer(value);
    }

    onDeleteHandler = (e) => {
        e.preventDefault();

        this.updateReducer(null, "delete");
    }

    updateReducer = (value, action="update") => {
        this.props.dataItemUpdate({
            id: this.props.id,
            value: value,
            action: action
        })
    }

    render () {

        const dataItem =  getItemById(this.props.data[this.props.section], this.props.id);

        return (
            <div>
                <div className="service-heading">
                    <h4>{dataItem.label}</h4>
                    <p><button className="button" onClick={this.onDeleteHandler}>X</button></p>
                </div>

                <div>
                    <div className="form-group">
                        <label>Лимит:</label>
                        <input className="w-100" name="limit" type="number" onChange={this.onChangeHandler} value={dataItem.value.limit}/>
                    </div>
                    <div className="form-group">
                        <label>{dataItem.value.applyLabel}</label>
                        <textarea name="text" type="text" onChange={this.onChangeHandler} value={dataItem.value.applyValue}/>
                    </div>


                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyItem);