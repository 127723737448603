import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/app';
import ErrorBoundry from "./components/error-boundry";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundry>
            <Router>
                <ScrollToTop />
                <App />
            </Router>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root')
);


