import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {fetchRequests, updateDelivery} from "../../../actions";
import {getTextDateTime} from "../../../utils/dates";
import {FiImage} from "react-icons/fi";

const STATUSES = [
    {label: 'Заявка оформлена', value: 'created'},
    {label: 'Заявка в работе', value: 'taken'},
    {label: 'Доставка выполнена', value: 'resolved'},
    {label: 'Доставка отменена', value: 'rejected'},
]

const DeliveryItem = props => {

    const { item, updateDelivery } = props

    const [fieldStatus, setFieldStatus] = useState(item.status || 'created')
    const [fieldResponse, setFieldResponse] = useState(item.response || '')
    const [fieldId, setFieldId] = useState(item._id || '')

    useEffect(() => {
        setFieldStatus(item.status || 'created')
        setFieldResponse(item.response || '')
        setFieldId(item._id || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const updateItemHandler = () => {
        updateDelivery(fieldId, {
           status: fieldStatus,
           response: fieldResponse
       })
    }

    const changeStatusHandler = (val) => {
        setFieldStatus(val);
        if (val !== 'resolved' && val !== 'rejected') {
            setFieldResponse('')
        }
    }

   const {service, deliveryItem, deliveryPrice, phone, name, createdAt, comment} = item


    return (
        <div>
            <div className='d-flex-wrap' style={{gap: 20}}>

                <div>
                    <p className='fs-10 mt-10'>{getTextDateTime(createdAt || new Date())}</p>
                    {name && <p className='fs-10 mt-10'>клиент:</p>}
                    {name && <p className='fs-14 mt-5'>{name}</p>}
                    {phone && <p className='fs-14 mt-5'>+{phone}</p>}

                    {name && <p className='fs-10 mt-20'>Доставка:</p>}
                    {service && <p className='fs-14 mt-10'>{service}</p>}
                    {deliveryItem && <p className='mt-10 fs-14'>{deliveryItem}</p>}
                    {deliveryPrice && <p className='mt-10 fs-14'>{deliveryPrice} бел руб</p>}
                    {deliveryPrice && <p className='mt-10 fs-14'>комментарий: {comment}</p>}
                    {(fieldStatus === 'resolved' || fieldStatus === 'rejected') && <div className='mt-10'>
                        <textarea className='w-300 h-100' placeholder='Введите сообщение для пользователя (опционально)' value={fieldResponse} onChange={e => setFieldResponse(e.target.value)} />
                    </div>}
                    <div className='mt-20 d-flex-wrap' style={{gap: 10}}>
                        <select value={fieldStatus} onChange={e => changeStatusHandler(e.target.value)} className='w-200'>
                            {STATUSES.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                        </select>
                        <button className='button-blue' onClick={updateItemHandler}>Обновить</button>
                    </div>
                </div>
            </div>
            <div className='separator1px mt-20 mb-20' />
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, requests }}) => {
    return { data, section, requests };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRequests: fetchRequests(dispatch),
        updateDelivery: updateDelivery(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryItem);
